<template>
  <div class="p-0 m-0" ref="imageUpload">
    <label v-show="showHeader" :for="id">
      <small class="m-1 text-red">*</small>{{ description }}
      <small class="ml-1 text-gray">{{ sizeDescription }}</small>
      <span id="postCover" class="col-sm-12 text-danger has-error d-none">請上傳圖檔</span>
    </label>
    <div :style="imgStyle"
         class="image-upload"
         :class="{
            'cursor-disabled': !isEditMode
         }"
         @click="showUploadDialog">
      <div :class="{
                  'image-choose': !isCoverExist,
                  'd-none': isCoverExist
                }">
        <i class="icon-upload-cloud" style="font-size:5rem;"></i>
        <div class="text-secondary">選擇圖片</div>
      </div>
      <div class="h-100">
        <img v-show="isCoverExist" class="img-thumbnail h-100" :src="imageSrc"/>
      </div>
    </div>
    <input :ref="refId"
           :id="id"
           type="file"
           class="form-control d-none"
           accept="image/jpg, image/jpeg, image/png"
           @change="showCropperModal"
    />
    <span v-show="isUpdateError" class="font-weight-bold text-red mr-2">請上傳圖片</span>
  </div>
</template>

<script>
import $ from "jquery"
import ModalList from "@/const/modal";
import { mapMutations } from "vuex"

export default {
  name: "CropperFileUploader",
  props: ['prevModalId','spec','value','isPreview'],
  emits: ['hide-prev-modal','sync-image-url','input'],
  data(){
    return {
      id: null,
      refId: null,
      showHeader: true,
      imageSrc: null,
      isUpdateError: false,
      file: null,
      frameWidth: 100,
      croppieData: {
        title: "上傳圖片",
        url: null,
        sizeLimit: 10000,
        autoCropWidth: 1200,
        autoCropHeight: 628,
        fixedNumber: [1200, 628],
        needThumbnail: false
      }
    }
  },
  created(){
    this.id = Math.floor(Math.random() * 1000)
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapMutations(['showModal','consumeUploaderData']),
    init(){
      let spec = this.spec ? this.spec : {}
      this.croppieData.title = spec.title? spec.title: '裁切圖片'
      let id = Math.floor(Math.random() * 1000)
      this.id = spec.id? spec.id: id
      this.refId = spec.refId? spec.refId: `${spec.type}-${id}`

      if(spec.isCrop){
        if(spec.fixedNumber){
          this.croppieData.fixedNumber = spec.fixedNumber
        }
        if(spec.autoCropWidth){
          this.croppieData.autoCropWidth = spec.autoCropWidth
        }
        if(spec.autoCropHeight){
          this.croppieData.autoCropHeight = spec.autoCropHeight
        }
      }
      this.croppieData.sizeLimit = spec.sizeLimit? spec.sizeLimit : 10000
      this.croppieData.needThumbnail = spec.needThumbnail? spec.needThumbnail : false
      this.frameWidth = spec.frameWidth? spec.frameWidth: 100
      this.showHeader = spec.showHeader? spec.showHeader : false

      this.syncImageSrc(this.value)
    },
    showUploadDialog(){
      if(this.isEditMode){
        $(this.$refs[this.refId]).click()
      }
    },
    showCropperModal(event){
      let files = event.target.files || event.dataTransfer.files
      if (!files.length){
        return
      }
      let msg = this.checkFileIsOverSize(files[0].size, this.sizeLimit)
      if(msg){
        alert(msg)
        this.resetFileInput()
        return
      }

      let reader = new FileReader();
      reader.onload = (e) => {
        this.croppieData.url = e.target.result
        let payload = {
          ...this.croppieData,
          id: this.id
        }

        if(this.prevModalId){
          payload.from = this.prevModalId
        }

        this.$emit('hide-prev-modal')

        this.showModal({
          modalId: ModalList.CROPPER_MODAL,
          payload: payload
        })

        this.resetFileInput()
      };

      reader.readAsDataURL(files[0])
    },
    resetFileInput(){
      this.$refs[this.refId].value = null
    },
    checkFileIsOverSize(fileSize, sizeLimit){
      let limit = sizeLimit? sizeLimit : this.croppieData.sizeLimit
      let isOverMessage = ''
      if(fileSize > limit * 1024){
        isOverMessage = `上傳的檔案超過限制的${limit / 1000}MB`
      }
      return isOverMessage
    },
    syncImageSrc(value){
      if(value instanceof File){
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result
          this.$emit('sync-image-url',this.imageSrc)
        };

        reader.readAsDataURL(value)
      } else if(typeof value == "string"){
        this.imageSrc = value
      } else {
        this.imageSrc = null
      }
    },
  },
  computed: {
    isCoverExist(){
      let isExist = false

      if(this.imageSrc){
        isExist = true
      }
      return isExist
    },
    sizeLimit(){
      let spec = this.spec ? this.spec : {}
      let limit = spec.sizeLimit? parseInt(spec.sizeLimit): this.croppieData.sizeLimit
      return limit && limit > 0 ? limit : 0
    },
    description(){
      let spec = this.spec ? this.spec : {}
      return spec.description ? spec.description : '預設封面'
    },
    sizeDescription(){
      let spec = this.spec ? this.spec : {}
      return spec.sizeDescription ? spec.sizeDescription : '建議大小 1200 * 628 (150 KB 內)'
    },
    imgStyle(){
      let style = {}
      if(this.frameWidth != 100){
        style = {
          width: `${this.frameWidth}%`
        }
      } else {
        style = {
          width: '100%'
        }
      }
      return style
    },
    isEditMode(){
      let isPreview = this.isPreview != undefined ? this.isPreview : false
      return !isPreview
    },
  },
  watch: {
    '$store.state.uploaderData': {
      handler(data){
        if(data[this.id]){
          this.imageSrc = data[this.id].url
          this.file = data[this.id].file

          this.$emit('input',data[this.id].file)
          this.consumeUploaderData(this.id)
        }
      },
      deep: true,
      immediate: true
    },
    value(value){
      this.syncImageSrc(value)
    }
  }
}
</script>

<style scoped>

.image-upload {
  border: 1px dotted #dee2e6;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload:hover {
  cursor: pointer;
  border: 2px solid #4fc3e3;
}

.cursor-disabled:hover {
  cursor: unset;
  border: 1px dotted #dee2e6;
}

.img-thumbnail {
  border: 1px solid #eee;
  padding: 1px;
}
</style>
