<template>
  <header id="header" class="nb-guide-header d-flex justify-content-center px-0 px-xl-4">
    <div class="header-inner d-flex align-items-center">
      <!--Logo-->
      <div id="logo">
        <a href="/" class="logo-wrapper d-flex align-items-center">
          <span class="logo-default nb-logo d-flex align-items-center">
            <img src="@/assets/images/help-guide/logo_blue.png" />
          </span>
        </a>
      </div>
      <!--End: Logo-->

      <!--Header Extras-->
      <div class="header-extras d-flex justify-content-end align-items-center">
        <nav
          id="menuNavigator"
          class="navigator"
          :class="{ mobile: this.fullWidth < 567, open: isMenuShow }"
        >
          <ul class="nav-list d-flex m-0" v-if="isLandingPage">
            <li
              class="nav-list-item d-flex align-items-center"
              :class="{ 'is-active': currentNavItem == item.id }"
              v-for="item in navList"
              :key="item.id"
            >
              <a
                class="d-flex align-items-center justify-content-center"
                @click.stop.prevent="toggleDropdown(item)"
              >
                <span
                  class="w-100"
                  @click.stop="goAnchor('#' + item.id, item)"
                  >{{ item.title }}</span
                >
                <!---->
                <span
                  class="d-flex list-item-dropdown-icon icon-btn dropdown-btn align-items-center h-100"
                  :class="{
                    'is-active': currentNavItem == item.id && isDropdownShow,
                  }"
                  v-if="item.childList"
                >
                  <img
                    src="@/assets/images/help-guide/dropdown-arrow.svg"
                    alt=""
                    class="toggle-icon"
                  />
                </span>
              </a>
              <transition name="fade">
                <ul
                  class="nav-item-child-list list-inline"
                  v-if="
                    currentNavItem == item.id &&
                    item.childList &&
                    isDropdownShow
                  "
                >
                  <li
                    class="child-list-item"
                    v-for="child in item.childList"
                    :key="child.id"
                  >
                    <a
                      class="d-flex justify-content-between"
                      @click.stop="goAnchor('#' + child.id, child, 'child')"
                    >
                      <span>{{ child.title }}</span>
                      <span class="nav-child-item-arrow icon-btn">
                        <img
                          src="@/assets/images/help-guide/item-arrow.svg"
                          alt=""
                        />
                      </span>
                    </a>
                  </li>
                </ul>
              </transition>
            </li>
          </ul>
        </nav>
        <button
          type="button"
          class="btn btn-in-nb-blue btn-xl ml-2"
          @click="register"
        >
          <span>立即前往</span>
        </button>
        <div
          class="menu-cion-wrapper d-flex d-sm-none"
          :class="{ open: isMenuShow }"
          @click="toggleMenu"
        >
          <span class="menu-icon"></span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { utilMixin } from "@/mixins/utilMixin";
export default {
  name: "Header",
  mixins: [utilMixin],
  props: {
    isLandingPage: {
      type: Boolean,
      default: false,
    },
    navList: {
      type: Array,
      default: () => [],
    },
    currentNavItem: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // detect width
      fullWidth: document.body.clientWidth,
      // mobile menu control
      isMenuShow: false,
      isDropdownShow: false,
    };
  },
  methods: {
    register() {
      // 註冊按鈕導向位置
      this.checkLoginStatusAndRedirect("/", "homepage");
    },
    // 手機板點選漢堡顯示header導覽列
    toggleMenu() {
      if (this.isMenuShow) {
        this.isMenuShow = false;
      } else {
        this.isMenuShow = true;
      }
    },
    // 關閉/顯示menu選單
    toggleDropdown(item) {
      this.$emit("toggle-dropdown", item);
      this.isDropdownShow = !this.isDropdownShow;
    },
    // 錨點定位
    goAnchor(selector, target, type) {
      if (target.id !== "nav1") {
        if (type == "child") {
          this.isDropdownShow = false;
        }
        if (this.fullWidth < 576) {
          this.isMenuShow = false;
        }
        this;
        let headerHeight = document.querySelector("#header").offsetHeight;
        let height = document.querySelector(selector).offsetTop;
        window.scrollTo({
          top: height - headerHeight,
          behavior: "smooth",
        });
      } else {
        this.toggleDropdown(target);
      }
    },
    // 點擊任意處關閉
    hideDropdown() {
      this.isDropdownShow = false;
    },
  },
  mounted() {
    document.addEventListener("click", this.hideDropdown);
    let vm = this;
    window.onresize = () => {
      vm.fullWidth = document.body.clientWidth;
    };
  },
};
</script>

<style scoped>
/* header */

.nb-guide-header {
  border-top: 9px solid var(--nb-blue-main);
  width: 100%;
  background-color: white;
  height: 127px;
  position: fixed;
  z-index: 999;
}

.nb-guide-header .header-inner {
  width: 100%;
}

.header-inner .logo-wrapper {
  text-decoration: none;
  color: var(--nb-blue-main);
}

.header-inner .logo-wrapper .nb-logo {
  width: 100%;
  max-width: 200px;
}

.header-inner .logo-wrapper img {
  width: 100%;
}

.header-inner .logo-wrapper .logo-text {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 0 12px;
}

.header-inner .header-extras {
  flex: 1;
  height: 100%;
}

.navigator {
  height: 100%;
}

.navigator .nav-list {
  height: 100%;
}

.navigator .nav-list .nav-list-item {
  position: relative;
  height: 100%;
  padding: 0 12px;
}

.navigator .nav-list .nav-list-item.is-active > a {
  color: var(--nb-blue-light);
}

.navigator .nav-list .nav-list-item > a .icon-btn > .toggle-icon {
  transition: linear 0.2s;
}

.navigator .nav-list .nav-list-item > a .icon-btn.is-active > .toggle-icon {
  transition: linear 0.2s;
  transform: rotate(180deg);
}

.navigator .nav-list .nav-list-item.is-active > a .icon-btn {
  filter: invert(69%) sepia(98%) saturate(312%) hue-rotate(152deg)
    brightness(86%) contrast(84%);
}

.navigator .nav-list-item {
  cursor: pointer;
}

.navigator .nav-list-item .list-item-dropdown-icon {
  margin: 0 0 0 4px;
  padding: 0 4px;
}

.navigator .nav-list .nav-list-item + .nav-list-item {
  margin-left: 8px;
}

.navigator .nav-list .nav-list-item a {
  color: var(--nb-black);
  height: 100%;
  width: 100%;
  padding: 8px 16px;
  font-weight: 700;
  letter-spacing: 0.04em;
}

.navigator .nav-list .nav-list-item > .nav-item-child-list {
  position: absolute;
  left: 0;
  top: 100%;
  width: max-content;
  background-color: var(--bg-grey-1);
  border-radius: 4px;
  z-index: 99;
}

.navigator .nav-item-child-list > .child-list-item a {
  color: var(--nb-blue-main);
  padding: 16px 28px;
}

.navigator .nav-list-item a:hover {
  color: var(--nb-blue-light);
}

.navigator .nav-item-child-list .nav-child-item-arrow {
  margin: 0 0 0 20px;
  filter: invert(16%) sepia(50%) saturate(2490%) hue-rotate(192deg)
    brightness(95%) contrast(85%);
}

.navigator .nav-list-item a:hover > .icon-btn {
  filter: invert(69%) sepia(98%) saturate(312%) hue-rotate(152deg)
    brightness(86%) contrast(84%);
}

/* mobile menu icon */
.menu-cion-wrapper {
  position: relative;
  height: 18px;
  width: 24px;
  margin: 0 0 0 12px;
}

.menu-icon {
  transition-duration: 0.5s;
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: var(--nb-blue-main);
  top: 9px;
}

.menu-icon::before {
  transition-duration: 0.5s;
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: var(--nb-blue-main);
  content: "";
  top: -9px;
}

.menu-icon::after {
  transition-duration: 0.5s;
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: var(--nb-blue-main);
  content: "";
  top: 9px;
}

.menu-cion-wrapper.open .menu-icon {
  transition-duration: 0.5s;
  background: transparent;
}

.menu-cion-wrapper.open .menu-icon::before {
  transform: translateY(9px) translateX(4.5px) rotate(45deg);
}

.menu-cion-wrapper.open .menu-icon::after {
  transform: translateY(-9px) translateX(4.5px) rotate(-45deg);
}

/* transition - animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* 1200 lg */
@media (max-width: 1200px) {
  .nb-guide-header .header-inner {
    padding: 0 20px;
  }

  .banner-bg-wrapper {
    bottom: 100px;
  }
}

/* 992 lg */
@media (max-width: 992px) {
  .navigator .nav-list .nav-list-item {
    padding: 0;
  }
  .header-inner .logo-wrapper .nb-logo {
    max-width: 145px;
  }
}

/* 768 lg */
@media (max-width: 768px) {
  .nb-guide-header {
    height: 80px;
    border-top: 6px solid var(--nb-blue-main);
  }

  .navigator .nav-list {
    font-size: 14px;
  }

  .navigator .nav-list .nav-list-item a {
    padding: 8px;
  }
}

/* 576 lg */
@media (max-width: 576px) {
  .banner-bg-wrapper {
    bottom: 330px;
  }

  .nb-guide-header {
    height: 70px;
    border-top: 4px solid var(--nb-blue-main);
  }

  .header-inner .logo-wrapper .nb-logo {
    max-width: 130px;
  }

  .btn-xl {
    padding: 8px 24px;
    font-size: 14px;
  }

  .navigator.mobile {
    transition: linear 0.3s;
    position: fixed;
    background-color: #fff;
    left: 0;
    width: 100vw;
    top: 70px;
    overflow: hidden;
    height: 0;
  }

  .navigator.mobile.open {
    transition: linear 0.3s;
    height: 100%;
  }

  .navigator.mobile .nav-list {
    flex-direction: column;
    width: 100%;
  }

  .navigator.mobile .nav-list .nav-list-item {
    height: fit-content;
    padding: 12px;
    flex-direction: column;
  }

  .navigator.mobile .nav-list .nav-list-item + .nav-list-item {
    margin: 8px 0 0 0;
  }

  .navigator.mobile .nav-list .nav-list-item a {
    justify-content: space-between;
  }

  .navigator.mobile .nav-list .nav-list-item > .nav-item-child-list {
    position: unset;
    width: 100%;
    padding: 0 0 0 20px;
    background: transparent;
  }

  .navigator.mobile .nav-list-item .list-item-dropdown-icon {
    padding: 0 8px;
  }

  .navigator .nav-list .nav-list-item {
    padding: 0;
  }
}
</style>
