import axios from "@/plugins/axios";

// identityVerify 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/me/identityVerify'

// get user identityVerify info
export function getUserIdentityVerify() {
    return axios.get(baseUrl).then(res=>res.data)
}

// send contact email check
export function sendContactEmailCheck() {
    return axios.post(`${baseUrl}/contactEmailCheck`)
}

// update user identityVerify
export function updateIdentityVerify(userIdentityVerifyDTO) {
    return axios.put(baseUrl, userIdentityVerifyDTO, { headers: {'Content-Type': 'application/json'} })
}
