import Vue from 'vue'
import Vuex from 'vuex'
import userStore from './user'
import adminStore from './admin'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        userStore: userStore.user,
        adminStore: adminStore.admin
    },
    state: {
        userId: null,
        loginUrl: null,
        categoryList:[],
        categoryMap:{},
        subCategoryMap:{},
        post: null,
        previousPost:{},
        nextPost:{},
        specialSelectedPost: null,
        uploaderData: {},
        modalQueue: [],
        manualHideModal: false,
        manualHideModalPayload: null,
        role:{
            NORMAL: '使用者',
            ADMIN: '管理員'
        },
        postField:{
            cover: '封面圖片',
            summary: '摘要',
            defaultCover: '是否使用預設封面圖片',
            showContact: '是否公開聯絡資訊',
            tag: '關鍵字',
            category: '分類',
            title: '標題',
            content: '內容'
        },
        userPromotionState: {
            REVIEWING: {
                name: '審核中',
                style: 'text-warning',
                badge: 'badge-secondary'
            },
            PENDING: {
                name: '等待開始',
                style: 'text-primary',
                badge: 'badge-secondary'
            },
            PROGRESS: {
                name: '進行中',
                style: 'text-success',
                badge: 'badge-success'
            },
            COMPLETED: {
                name: '已結束',
                style: 'text-secondary',
                badge: 'badge-primary'
            },
            REJECT: {
                name: '審核未通過',
                style: 'text-danger',
                badge: 'badge-secondary'
            }
        },
        postState:{
            PUBLISHED: {
                name: '已刊登',
                style: 'text-info'
            },
            TEMP: {
                name: '草稿',
                style: 'text-secondary'
            },
            WITHDREW: {
                name: '已下架',
                style: 'text-danger'
            },
            REMOVED: {
                name: '撤文',
                style: 'text-warning'
            }
        },
        trekId:{
            campId: 'Trek Campaign ID',
            adSetId: 'Trek AdSet ID',
            adUnitId: 'Trek AdUnit ID'
        },
        manualSelected: {
            pin:{
                type: 'pin',
                name: '置頂區新聞稿'
            },
            selected:{
                type: 'selected',
                name: '精選新聞稿'
            },
            specialSelected:{
                type: 'special-selected',
                name: '特選新聞稿'
            },
            tag:{
                type: 'tag',
                name: '熱門標籤'
            }
        },
        postListMetaInfo:[
            {
                vmid: 'description',
                name: 'description',
                content: '想要免費發佈新聞稿、免費曝光？讓更多人看到自家推出的新服務或新產品嗎？可以試試 Aotter （電獺）的媒體服務「新聞稿自助吧｜ NewsBuffet」！這是一個「免費」的新聞稿平台，不僅可以免費上新聞稿，上稿程序簡單又方便，「新聞稿自助吧｜ NewsBuffet」是由 Aotter （電獺）維護的新聞稿自助上線平台，獲選的稿件會排程至每月超過百萬人瀏覽的鄉民晚報 APP 上，是您曝光消息的絕佳管道！',
            },
            {
                property: 'og:title',
                content: '新聞稿自助吧 | NewsBuffet'
            },
            {
                property: 'og:type',
                content: 'website'
            },
            {
                property: 'og:url',
                content: 'https://nb.aotter.net'
            },
            {
                property: 'og:description',
                content: '想要免費發佈新聞稿、免費曝光？讓更多人看到自家推出的新服務或新產品嗎？可以試試 Aotter （電獺）的媒體服務「新聞稿自助吧｜ NewsBuffet」！這是一個「免費」的新聞稿平台，不僅可以免費上新聞稿，上稿程序簡單又方便，「新聞稿自助吧｜ NewsBuffet」是由 Aotter （電獺）維護的新聞稿自助上線平台，獲選的稿件會排程至每月超過百萬人瀏覽的鄉民晚報 APP 上，是您曝光消息的絕佳管道！'
            },
            {
                property: 'og:image',
                content: 'https://nb.aotter.net/static/images/fbmeta.png'
            },
            {
                property: 'og:image:width',
                content: '1200'
            },
            {
                property: 'og:image:height',
                content: '627'
            },
            {
                property: 'og:site_name',
                content: '新聞稿自助吧 | NewsBuffet'
            },
            {
                name: 'twitter:title',
                content: '新聞稿自助吧 | NewsBuffet'
            },
            {
                name: 'twitter:url',
                content: 'https://nb.aotter.net'
            },
            {
                name: 'twitter:description',
                content: '想要免費發佈新聞稿、免費曝光？讓更多人看到自家推出的新服務或新產品嗎？可以試試 Aotter （電獺）的媒體服務「新聞稿自助吧｜ NewsBuffet」！這是一個「免費」的新聞稿平台，不僅可以免費上新聞稿，上稿程序簡單又方便，「新聞稿自助吧｜ NewsBuffet」是由 Aotter （電獺）維護的新聞稿自助上線平台，獲選的稿件會排程至每月超過百萬人瀏覽的鄉民晚報 APP 上，是您曝光消息的絕佳管道！'
            },
            {
                name: 'twitter:image',
                content: 'https://nb.aotter.net/static/images/fbmeta.png'
            }
        ],
        postContentMetaInfo:[],
        identityVerifyStatus:{
            PENDING: {
                name: '未驗證',
                style: 'text-danger',
                icon: 'icon-x-circle'
            },
            UNDER_REVIEW: {
                name: '審核中',
                style: 'text-warning',
                icon: 'icon-alert-circle'
            },
            APPROVED: {
                name: '通過驗證',
                style: 'text-success',
                icon: 'icon-check-circle'
            },
            REJECTED: {
                name: '審核拒絕',
                style: 'text-danger',
                icon: 'icon-x-circle'
            },
        },
    },
    getters: {
        getRoleName: (state) => (role) =>{
            let name = ''
            for (const [key, value] of Object.entries(state.role)) {
                if(key == role){
                    name =  value
                }
            }
            return name
        },
        getPostFieldName: (state) => (field) =>{
            let name = ''
            for (const [key, value] of Object.entries(state.postField)) {
                if(key == field){
                    name =  value
                }
            }
            return name
        },

        getUserPromotionStateName: (state) => (promotionState) => {
            let name = ''
            for (const [key, value] of Object.entries(state.userPromotionState)) {
                if(key == promotionState){
                    name =  value.name
                }
            }
            return name
        },
        getUserPromotionStateStyle: (state) => (promotionState) => {
            let style = ''
            for (const [key, value] of Object.entries(state.userPromotionState)) {
                if(key == promotionState){
                    style =  value.style
                }
            }
            return style
        },
        getUserPromotionStateBadge: (state) => (promotionState) => {
            let badge = ''
            for (const [key, value] of Object.entries(state.userPromotionState)) {
                if(key == promotionState){
                    badge =  value.badge
                }
            }
            return badge
        },
        getPostStateName: (state) => (postState) => {
            let name = ''
            for (const [key, value] of Object.entries(state.postState)) {
                if(key == postState){
                    name =  value.name
                }
            }
            return name
        },
        getPostStateStyle: (state) => (postState) => {
            let style = ''
            for (const [key, value] of Object.entries(state.postState)) {
                if(key == postState){
                    style =  value.style
                }
            }
            return style
        },
        getTrekIdName: (state) => (id) => {
            let idName = ''
            for (const [key, value] of Object.entries(state.trekId)) {
                if(key == id){
                    idName =  value
                }
            }
            return idName
        },
        getManualSelectedName: (state) => (type) => {
            let name = ''
            for (const [, value] of Object.entries(state.manualSelected)) {
                if(value.type == type){
                    name =  value.name
                }
            }
            return name
        },
        getIdentityVerifyStatusName: (state) => (identityVerifyStatus) => {
            let name = ''
            for (const [key, value] of Object.entries(state.identityVerifyStatus)) {
                if(key == identityVerifyStatus){
                    name =  value.name
                }
            }
            return name
        },
        getIdentityVerifyStatusStyle: (state) => (identityVerifyStatus) => {
            let style = ''
            for (const [key, value] of Object.entries(state.identityVerifyStatus)) {
                if(key == identityVerifyStatus){
                    style =  value.style
                }
            }
            return style
        },
        getIdentityVerifyStatusIcon: (state) => (identityVerifyStatus) => {
            let icon = ''
            for (const [key, value] of Object.entries(state.identityVerifyStatus)) {
                if(key == identityVerifyStatus){
                    icon =  value.icon
                }
            }
            return icon
        },
    },
    mutations: {
        updateUserId(state, payload){
            state.userId = payload
        },
        updateLoginUrl(state, payload){
            state.loginUrl = payload
        },
        updateCategoryList(state, payload){
            state.categoryList = payload
        },
        updatePost(state, payload){
            state.post = payload
        },
        updatePreviousPost(state, payload){
            state.previousPost = payload
        },
        updateNextPost(state, payload){
            state.nextPost = payload
        },
        updateSpecialSelectedPost(state, payload){
            state.specialSelectedPost = payload
        },
        getCategoryMap(state){
            state.categoryList.forEach(item =>{
                state.categoryMap[item.category] = item.categoryZh
            })
        },
        getSubCategoryMap(state){
            state.categoryList.forEach(item =>{
                let subCategoryList = item.subCategory
                subCategoryList.forEach(item =>{
                    state.subCategoryMap[item.industry] = item.industryZh
                })
            })
        },
        setUploaderData(state, payload){
            if(payload.id && payload.data){
                state.uploaderData = {
                    ...state.uploaderData,
                    [payload.id]: payload.data
                }
            }
        },
        consumeUploaderData(state, payload){
            let id = payload

            if(state.uploaderData[id]){
                delete state.uploaderData[id]
            }
        },
        showModal(state,payload){
            state.manualHideModalPayload = null
            state.modalQueue.push(payload)
        },
        consumeModal(state){
            state.modalQueue.pop()
        },
        hideModal(state){
            state.manualHideModal = true
        },
        consumeManualHideModal(state){
            state.manualHideModal = false
        },
        updatePostContentMetaInfo(state, payload){
            if(Object.keys(payload).length > 0){
                let basicMeta = [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: payload.summary,
                    },
                    {
                        property: 'og:title',
                        content: payload.title
                    },
                    {
                        property: 'og:type',
                        content: 'website'
                    },
                    {
                        property: 'og:url',
                        content: payload.seoUrl
                    },
                    {
                        property: 'og:description',
                        content: payload.summary
                    },
                    {
                        property: 'og:image',
                        content: payload.cover
                    },
                    {
                        property: 'og:site_name',
                        content: '新聞稿自助吧 | NewsBuffet'
                    },
                    {
                        name: 'twitter:title',
                        content: payload.title
                    },
                    {
                        name: 'twitter:url',
                        content: payload.seoUrl
                    },
                    {
                        name: 'twitter:description',
                        content: payload.summary
                    },
                    {
                        name: 'twitter:image',
                        content: payload.cover
                    },
                    {
                        property: 'dable:item_id',
                        content: payload.id
                    },
                    {
                        property: 'dable:title',
                        content: payload.title
                    },
                    {
                        property: 'dable:image',
                        content: payload.cover
                    },
                    {
                        property: "article:published_time",
                        content: payload.publishedTime
                    }
                ]
                let categoryMeta = []
                payload.category.forEach(category => {
                    categoryMeta.push({
                        property: "article:section",
                        content: category.categoryZh
                    })

                    category.subCategory.forEach(subCategory => {
                        categoryMeta.push({
                            property: "article:section2",
                            content: subCategory.industryZh
                        })
                    })
                })

                state.postContentMetaInfo = basicMeta.concat(categoryMeta)
            }
        }
    }
})
