import { render, staticRenderFns } from "./PostList.vue?vue&type=template&id=6fed6416&scoped=true&"
import script from "./PostList.vue?vue&type=script&lang=js&"
export * from "./PostList.vue?vue&type=script&lang=js&"
import style0 from "./PostList.vue?vue&type=style&index=0&id=6fed6416&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fed6416",
  null
  
)

export default component.exports