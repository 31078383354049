import { email, required } from "vee-validate/dist/rules";

export default {
    required: {
        ...required,
        message: '不可空白'
    },
    email: {
        ...email,
        message: '格式錯誤'
    },
    category: (category) =>{
        if (!category) {
            return '請選擇分類'
        } else{
            return true
        }
    },
    title: (title) =>{
        if (title.trim().length > 50 || title.trim().length < 5) {
            return '請再檢查看看，字數長度需介於 5 - 50 字'
        }else {
            return true
        }
    },
    summary: (summary) =>{
        if (summary.trim().length > 350 || summary.trim().length < 5) {
            return '請再檢查看看，字數長度需介於 5 - 350 字'
        }else {
            return true
        }
    },
    content: (content) =>{
        if (content.trim().length < 150) {
            return '請再檢查看看，字數必須大於 150'
        }else {
            return true
        }
    },
    promotionTitle: (promotionTitle) =>{
        if (promotionTitle.trim().length > 25 || promotionTitle.trim().length < 3) {
            return '請檢查字數長度，須介於 3 - 25 字'
        } else{
            return true
        }
    },
    promotionSummary: (promotionSummary) =>{
        if (promotionSummary.trim().length > 35 || promotionSummary.trim().length < 3) {
            return '請檢查字數長度，須介於 3 - 35 字'
        } else{
            return true
        }
    },
    budget: (budget) =>{
        if (budget < 1000) {
            return '請檢查推廣金額最低為1000'
        } else if(budget > 30000){
            return '請檢查推廣金額最高為30000'
        }else if (budget % 100 !== 0){
            let minAmount = Math.floor(budget/100) * 100
            let maxAmount = Math.ceil(budget/100) * 100
            return `請輸入有效值。最接近的兩個有效值分別是${minAmount}和${maxAmount}。`
        }else{
            return true
        }
    },
    day: (day) =>{
        if (!day) {
            return '請選擇走期'
        } else{
            return true
        }
    },
    name: (name) =>{
        if (name.trim().length > 35 || name.trim().length < 3) {
            return '請檢查字數長度，須介於 3 - 35 字'
        } else{
            return true
        }
    },
    userId: (userId) =>{
        if (!/[a-zA-Z0-9]{24}/.test(userId)) {
            return '必須包含大小寫英文字及數字，且長度為24字'
        } else if(userId.trim().length > 24){
            return '長度需為24字'
        } else{
            return true
        }
    },
    afterNow: (timeStr) =>{
        let time = new Date(timeStr)
        let now = new Date()

        if (time.getTime() < now.getTime()) {
            return '需大於當前時間'
        } else{
            return true
        }
    },
    isAfter: (endTimeStr,[startTimeStr]) => {
        let endTime = new Date(endTimeStr)
        let startTime = new Date(startTimeStr)

        if (endTime.getTime() < startTime.getTime()) {
            return '需大於開始時間'
        } else{
            return true
        }
    },
    publisher: (publisher) =>{
        if (publisher.trim().length < 2 || publisher.trim().length > 20) {
            return '請檢查字數長度，須介於 2 - 20 字'
        } else if(!/^[\d|a-zA-Z |\u4e00-\u9fa5]+$/.test(publisher)){
            return '不可以包含特殊符號，請再檢查看看'
        } else{
            return true
        }
    },
    contactName: (contactName) =>{
        if (contactName.trim().length < 3 || contactName.trim().length > 20) {
            return '請檢查字數長度，須介於 3 - 20 字'
        } else if(!/^[\d|a-zA-Z |\u4e00-\u9fa5]+$/.test(contactName)){
            return '不可以包含特殊符號，請再檢查看看'
        } else{
            return true
        }
    },
    contactPhone: (contactPhone) =>{
        const taiwanCellPhoneNum = /^09+[0-9]{2}-[0-9]{6}$/
        const taiwanPhoneNum = /^0+[1-9]{1}-[0-9]{7,8}$/
        const internationalPhoneNum = /^\+(?:[0-9] ?){6,14}[0-9]$/

        if (!taiwanCellPhoneNum.test(contactPhone) && !taiwanPhoneNum.test(contactPhone) && !internationalPhoneNum.test(contactPhone)) {
            return '格式錯誤'
        } else{
            return true
        }
    },
    businessIdNumber: (businessIdNumber) =>{
        const num = 8
        const pattern = /^[0-9]{8}$/
        if(businessIdNumber.trim().length < num){
            return '格式錯誤'
        } else if (!pattern.test(businessIdNumber)) {
            return '格式錯誤'
        } else{
            return true
        }
    },
    policyCheck: (policyCheck) =>{
        if (!policyCheck) {
            return '請詳細閱讀並同意'
        } else{
            return true
        }
    },

}
