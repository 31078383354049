<template>
  <div :ref='modalId'  class="modal fade" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content p-2">
        <div class="modal-body">
          <div class="post-item-description">
            <div v-if="post.category" class="mb-2" id="postCategory">
              <template v-for="category in post.category">
                <span class="text-bold text-black-50" :key="category.id">
                  <i class="fa fa-tag mr-1"></i>{{category.categoryZh}}
                </span>
                <template v-for="subCategory in category.subCategory">
                  <span class="btn-nostyle btn-sm text-black-50" :key="subCategory.id">{{subCategory.industryZh}}</span>
                </template>
              </template>
            </div>
            <h3 id="postTitle">{{post.title}}</h3>
            <div class="m-1 mb-2 pb-2 d-flex justify-content-between">
              <div class="text-bold text-black-50">
                <i id="postPublishTime" class="fa fa-calendar-o">{{post.publishTime | timeString('YYYY-MM-DD HH:mm:ss')}}</i>
              </div>
            </div>
            <div class="text-center">
              <img id="postCover" class="img-thumbnail border-0 mb-3" :src="post.cover">
            </div>
            <p class="force-line-break" id="postContent" v-html="post.content"></p>
            <div v-if="post.tag" id="postTag" class="post-tags">
              <template v-for="(tag,index) in post.tag">
                <button class="btn-sm btn-nostyle ml-1" :key="index">{{tag}}</button>
              </template>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">關閉</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import { getPostById, findPostRecord } from '@/service/api/internal/me/post';
import { adminGetPostById } from "@/service/api/internal/admin/post";

export default {
  name: "PostPreviewModal",
  data(){
    return {
      modalId: ModalList.POST_PREVIEW_MODAL,
      data: {
        type: null,
        postId: null,
        recordId: null
      },
      post:{}
    }
  },
  methods: {
    show(data){
      data = data ? data : {}
      this.data.type = data.type ? data.type : ''
      this.data.postId = data.postId ? data.postId : ''
      this.data.recordId = data.recordId ? data.recordId : ''
      if(data){
        this.getPost()
      }
      $(this.$refs[this.modalId]).modal('show')
    },
    async getPost(){
      switch (this.data.type){
        case 'userCurrentPost' :
          await getPostById(this.data.postId)
              .then( post =>{this.post = post})
          break;
        case 'adminCurrentPost' :
          await adminGetPostById(this.data.postId)
              .then( post =>{this.post = post})
          break;
        case 'recordPost' :
          await findPostRecord(this.data.postId, this.data.recordId)
              .then( post =>{this.post = post})
          break;
      }
    }
  }
}
</script>

<style>

#postContent img {
  max-width: 100%;
}

.force-line-break{
  width: 100%;
  height: auto;
  word-wrap:break-word;
  word-break:break-all;
  overflow: hidden;
}

</style>
