<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="false">
    <div class="modal-dialog modal-lg">
      <div class="modal-content p-2">
        <div class="modal-body">
          <form>
            <!-- 發佈單位名稱 -->
            <div class="form-group row">
              <label for="publisher" class="col-sm-2 col-form-label">單位名稱</label>
              <div class="col-sm-10">
                <input type="text" readonly class="form-control-plaintext" id="publisher" :value="identityVerify.publisher">
              </div>
            </div>
            <!-- 發佈單位統一編號 -->
            <div class="form-group row">
              <label for="businessIdNumber" class="col-sm-2 col-form-label">統一編號</label>
              <div class="col-sm-10">
                <input type="text" readonly class="form-control-plaintext" id="businessIdNumber" :value="identityVerify.businessIdNumber">
              </div>
            </div>
            <!-- 發佈單位成立登記證明 -->
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">成立登記證明</label>
              <div class="col-sm-10">
                <img :src="identityVerify.registrationCertificate">
              </div>
            </div>
            <!-- 新聞聯絡人 中文姓名 -->
            <div class="form-group row">
              <label for="contactName" class="col-sm-2 col-form-label">新聞聯絡人</label>
              <div class="col-sm-10">
                <input type="text" readonly class="form-control-plaintext" id="contactName" :value="identityVerify.contactName">
              </div>
            </div>
            <!-- 新聞聯絡人 聯絡電話 -->
            <div class="form-group row">
              <label for="contactPhone" class="col-sm-2 col-form-label">聯絡電話</label>
              <div class="col-sm-10">
                <input type="text" readonly class="form-control-plaintext" id="contactPhone" :value="identityVerify.contactPhone">
              </div>
            </div>
            <!-- 新聞聯絡人 聯絡信箱 -->
            <div class="form-group row">
              <label for="contactEmail" class="col-sm-2 col-form-label">聯絡信箱</label>
              <div class="col-sm-10">
                <input type="text" readonly class="form-control-plaintext" id="contactEmail" :value="identityVerify.contactEmail">
              </div>
            </div>
            <!-- 聯絡人名片 -->
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">聯絡人名片</label>
              <div class="col-sm-10">
                <template v-for="(businessCard, index) in identityVerify.businessCard">
                  <div :key="index" class="mt-1">
                    <img :src="businessCard">
                  </div>
                </template>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-0">
          <template v-if="data.status == Const().APPLICATION_STATUS_UNDER_REVIEW">
            <button type="button" class="btn btn-success" @click="showUpdateIdentityVerifyModal(Const().APPLICATION_STATUS_APPROVED)">審核通過</button>
            <button type="button" class="btn btn-danger" @click="showUpdateIdentityVerifyModal(Const().APPLICATION_STATUS_REJECTED)">審核拒絕</button>
          </template>
          <template v-else>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">關閉</button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalList from "@/const/modal";
import $ from "jquery";
import {findIdentityVerify} from "@/service/api/internal/admin/identityVerify";
import {utilMixin} from "@/mixins/utilMixin";
import {mapMutations} from "vuex";

export default {
  name: "IdentityVerifyPreviewModal",
  mixins: [utilMixin],
  data(){
    return {
      modalId: ModalList.IDENTITY_VERIFY_PREVIEW_MODAL,
      data: {
        id: null,
        status: null,
        updateFunc: () => {}
      },
      identityVerify:{}
    }
  },
  methods: {
    ...mapMutations(['showModal', 'hideModal']),
    show(data){
      data = data ? data : {}
      this.data.id = data.id ? data.id : ''
      this.data.status = data.status ? data.status : ''
      this.data.updateFunc = data.updateFunc ? data.updateFunc : null
      if(data){
        this.getIdentityVerify()
      }
      $(this.$refs[this.modalId]).modal('show')
    },
    async getIdentityVerify(){
      await findIdentityVerify(this.data.id)
          .then( identityVerify =>{this.identityVerify = identityVerify})
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    showUpdateIdentityVerifyModal(result){
      window.setTimeout(()=>{
        this.hide()
        this.showModal({
          modalId: ModalList.UPDATE_IDENTITY_VERIFY_MODAL,
          payload: {
            identityVerify: this.identityVerify,
            status: result,
            updateFunc: this.data.updateFunc
          }
        })
        this.identityVerify = {}
      },1000)
    },
  }
}
</script>

<style scoped>
#postContent img {
  max-width: 100%;
}

.force-line-break{
  width: 100%;
  height: auto;
  word-wrap:break-word;
  word-break:break-all;
  overflow: hidden;
}
</style>
