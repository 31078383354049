import axios from "@/plugins/axios";

// user 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/admin/user'

// find user by id
export function findUser(id){
    return axios.get(`${baseUrl}/${id}`).then(res=>res.data)
}

// find user list
export function findUserList(query){
    return axios.get(`${baseUrl}/list?${query}`).then(res=>res.data)
}

// update user roles
export function updateRoles(dto){
    return axios.put(`${baseUrl}/roles`, dto, { headers: {'Content-Type': 'application/json'} })
}

// update user state
export function updateState(dto){
    return axios.put(`${baseUrl}/state`, dto, { headers: {'Content-Type': 'application/json'} })
}

// update user identityVerify
export function updateIdentityVerify(dto){
    return axios.put(`${baseUrl}/identityVerify`, dto, { headers: {'Content-Type': 'application/json'} })
}
