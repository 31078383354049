<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-9">
          <span class="h4">新聞稿管理</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="form-inline my-2 my-lg-0" style="justify-content: end">
        <input class="form-control mr-sm-2 w-100 mb-2" placeholder="輸入使用者編號" v-model="search.userId">
        <input class="form-control mr-sm-2 w-100 mb-2" placeholder="輸入新聞稿編號" v-model="search.postId">
        <input class="form-control mr-sm-2 w-100 mb-2" placeholder="輸入文章標題" v-model="search.title">
        <button class="btn btn-outline-success my-2 my-sm-0" @click="searchByQuery(getUserPostList, {page: 1, ...search})">搜尋新聞稿</button>
        <button class="btn btn-danger my-2 my-sm-0" @click="resetSearch">重設搜尋</button>
      </div>
      <div class="mt-2 d-flex flex-wrap flex-md-nowrap justify-content-end">
        <div class="d-flex">
          <div class="text-secondary align-self-center" id="resultCount">
            共<span class="mr-1 ml-1">{{totalData}}</span>筆
          </div>

          <div class="ml-3">
            <select name="state" class="border-0 font-weight-bold text-primary mr-2"
                    v-model="search.state" @change="searchByQuery(getUserPostList, {page: 1, ...search})">
              <option value="">所有文章</option>
              <option value="TEMP">草稿</option>
              <option value="PUBLISHED">已刊登</option>
              <option value="WITHDREW">已下架</option>
              <option value="REMOVED">撤文</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-outter wrapper">
        <table v-if="currentPageDataList.length > 0" id="dataTable" class="table table-hover table-responsive vertical-middle text-center mt-4">
          <thead>
          <tr>
            <th scope="col" class="col-75" width="10%">狀態</th>
            <th scope="col" class="col-100" width="20%">封面</th>
            <th scope="col" class="col-200" width="40%">標題</th>
            <th scope="col" class="col-75" width="20%">更新時間</th>
            <th scope="col" class="col-50" width="10%">詳情</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(post, postIndex) in currentPageDataList">
            <tr :key="`${post.id}-1`">
              <td class="col-75" :class="getPostStateStyle(post.state)">{{getPostStateName(post.state)}}</td>
              <td class="col-100"><img :src="post.cover ? post.cover : ''" class="img-fluid"></td>
              <td class="col-200">{{post.title}}</td>
              <td class="col-75">{{ post.modifyTime | timeString('YYYY-MM-DD') }}<br>{{ post.modifyTime | timeString('HH:mm:ss') }}</td>
              <td class="col-50"><i class="icon-monitor pointIcon" @click="toggleDetail(`collapse-${postIndex}`)"></i></td>
            </tr>
            <tr :key="`${post.id}-2`" :ref="`collapse-${postIndex}`" class="collapse" data-parent="#dataTable">
              <td colspan="8">
                <div class="m-1">
                  <div class="mb-3 text-left">
                    <a class="mr-2 text-primary pointIcon" @click="showPostPreviewModal(post.id)">預覽
                      <i class="icon-eye pointIcon"></i>
                    </a>
                    <a v-if="post.state == 'PUBLISHED'" class="mr-2 text-danger pointIcon" @click="showDeletePostModal(post.id, post.title, post.state)">撤文
                      <i class="ml-1 icon-trash"></i>
                    </a>
                  </div>
                  <div class="mb-2 text-left" v-if="post.publishTime">
                    <div class="text-primary">刊登時間</div>
                    <div>{{post.publishTime | timeString('YYYY-MM-DD HH:mm:ss')}}</div>
                  </div>
                  <div class="mb-2 text-left">
                    <div class="text-primary">新聞稿編號</div>
                    <div>{{post.id}}</div>
                  </div>
                  <div class="mb-2 text-left">
                    <div class="text-primary">使用者編號</div>
                    <div>{{post.user.id}}</div>
                  </div>
                  <div class="mb-2 text-left">
                    <div class="text-primary">分類</div>
                    <template v-if="post.categoryList">
                      <div v-for="category in post.categoryList" :key="category.id">
                        <div>
                          <span class="mr-2">{{category.categoryZh}}</span>
                          <template v-for="(subCategory, index) in category.subCategory">
                            <span class="text-black-50" :key="subCategory.id">{{subCategory.industryZh}}
                              <span class="text-black-50" v-if="index != (category.subCategory.length-1)"> , </span>
                            </span>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="mb-2 text-left">
                    <div class="text-primary">公開資訊
                      <a v-if="post.showContact == true"
                          class="mr-2 text-primary pointIcon"
                         @click="showUserPreviewModal(post.user.id)">檢視
                        <i class="icon-eye pointIcon"></i>
                      </a>
                    </div>
                    <label>{{post.showContact? '是' : '否'}}</label>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <div v-else id="result-hint" class="d-flex justify-content-center">
          <template v-if="isEmptyList">尚無資料</template>
          <template v-else><loading/></template>
        </div>
      </div>
      <!-- Pagination -->
      <ul v-if="currentPageDataList.length > 0" class="pagination justify-content-center">
        <li class="page-item">
          <a class="page-link" @click="currentPage != 1 ? getPreviousPage(currentPage, getUserPostList):''"><i class="fa fa-angle-left"></i></a>
        </li>
        <template v-for="page in range">
          <li class="page-item" :class="{'active': page === currentPage}" :key="page">
            <a class="page-link" @click="currentPage != page ? toTargetPage(page, getUserPostList):''" style="cursor: default;">{{page}}</a>
          </li>
        </template>
        <li class="page-item">
          <a class="page-link" @click="currentPage != totalPage ? toNextPage(currentPage, totalPage, getUserPostList):''"><i class="fa fa-angle-right"></i></a>
        </li>
      </ul>
      <!-- end: Pagination -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import Loading from "@/components/common/Loading";
import { utilMixin } from "@/mixins/utilMixin";
import { mapGetters, mapMutations } from "vuex";
import { deletePost, findAllPostList } from "@/service/api/internal/admin/post";

export default {
  name: "AllPostList",
  mixins: [utilMixin],
  components: {
    Loading
  },
  data(){
    return{
      search:{
        userId: '',
        postId: '',
        title: '',
        state: '',
      }
    }
  },
  computed:{
    ...mapGetters([
      'getPostStateName','getPostStateStyle'
    ])
  },
  mounted() {
    this.initSearchParam()
    this.getUserPostList(this.currentPage)
  },
  methods:{
    ...mapMutations(['showModal']),
    initSearchParam(){
      let search = JSON.parse(JSON.stringify(this.search))
      this.search = this.initSearchData(search)
      if(!this.search.state){
        this.search.state= ''
      }
    },
    async getUserPostList(page){
      let currentPage = this.checkPageValid(page, this.currentPage, this.totalPage)
      this.currentPage = currentPage
      let query = this.getSearchQuery(this.currentPage, this.show, this.search)
      let response = await findAllPostList(query)
      this.currentPageDataList = response.data
      this.totalPage = response.totalPage
      this.totalData = response.totalElement
      if(this.currentPageDataList.length == 0){
        this.isEmptyList = true
      }
    },
    toggleDetail(refId){
      if(this.$refs[refId]){
        $(this.$refs[refId]).collapse('toggle')
      }
    },
    showPostPreviewModal(postId){
      this.showModal({
        modalId: ModalList.POST_PREVIEW_MODAL,
        payload: {
          type: 'adminCurrentPost',
          postId: postId
        }})
    },
    showDeletePostModal(id, title, state){
      this.showModal({
        modalId: ModalList.POST_DELETE_MODAL,
        payload: {
          role: 'admin',
          postId: id,
          title: title,
          state: state,
          delete: this.deletePost
        }})
    },
    showUserPreviewModal(userId){
      this.showModal({
        modalId: ModalList.USER_PREVIEW_MODAL,
        payload: {userId: userId}
      })
    },
    resetSearch(){
      let state = this.search.state
      this.search = {}
      this.search.state = state
      this.search.title = ""
      this.search.userId = ""
      this.search.postId = ""
      if(window.location.search) {
        this.searchByQuery(this.getUserPostList, {page: 1, ...this.search})
      }
    },
    async deletePost(data){
      await deletePost(data.postId, data.state)
          .then(res =>{
            if(res.status == 204){
              // update current post list
              let page = parseInt(this.$route.query.page ? this.$route.query.page : 1)
              this.getUserPostList(page)
              this.showSuccessModal({ maxCountDownSec: 3, content: `已成功撤文新聞稿 ${data.title}` })
            }else {
              this.showErrorModal()
            }
          })
    }
  }
}
</script>

<style scoped>
.pointIcon{
  cursor: pointer;
  color: rgb(24 135 255)
}

.table-outter {
  overflow-x: scroll;
}

.col-200 {
  min-width: 200px;
}

.col-100 {
  min-width: 100px;
}

.col-75 {
  min-width: 75px;
}

.col-50 {
  min-width: 50px;
}
</style>
