<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-9">
          <span class="h4">企業身份驗證管理</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="form-inline my-2 my-lg-0">
        <div class="w-100">
          <input class="form-control mr-sm-2 w-100 mb-2" placeholder="輸入email, 發佈單位名稱, 申請人" v-model="search.keyword">
          <div class="d-flex">
            <div class="form-group w-50">
              <label class="mr-3" for="state">申請狀態</label>
              <select id="state" class="w-100 form-control mr-2" v-model="search.status">
                <option value="">全部</option>
                <option value="APPROVED">通過驗證</option>
                <option value="UNDER_REVIEW">審核中</option>
                <option value="REJECTED">審核拒絕</option>
              </select>
            </div>
          </div>
        </div>
        <div class="w-100 mt-3 d-flex justify-content-end">
          <button class="btn btn-outline-success my-2 my-sm-0" @click="searchByQuery(getIdentityVerifyList, {page: 1, ...search})">搜尋</button>
          <button class="btn btn-danger my-2 my-sm-0" @click="resetSearch">重設搜尋</button>
        </div>
      </div>
      <div class="mt-2 d-flex flex-wrap flex-md-nowrap justify-content-end">
        <div class="d-flex">
          <div class="text-secondary align-self-center" id="resultCount">
            共<span class="mr-1 ml-1">{{totalData}}</span>筆
          </div>
        </div>
      </div>
      <div class="table-outter wrapper">
        <table v-if="currentPageDataList.length > 0" id="dataTable" class="table table-hover table-responsive vertical-middle text-center mt-4">
          <thead>
          <tr>
            <th scope="col" class="col-75" width="10%">狀態</th>
            <th scope="col" class="col-100" width="30%">聯絡人email</th>
            <th scope="col" class="col-100" width="30%">發佈單位名稱</th>
            <th scope="col" class="col-75" width="10%">提出日期</th>
            <th scope="col" class="col-50" width="10%">詳情</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(identityVerify, identityVerifyIndex) in currentPageDataList">
            <tr :key="`${identityVerify.id}-1`">
              <td class="col-75" :class="getIdentityVerifyStatusStyle(identityVerify.status)">{{getIdentityVerifyStatusName(identityVerify.status)}}</td>
              <td class="col-100">{{identityVerify.contactEmail}}</td>
              <td class="col-100">{{identityVerify.publisher}}</td>
              <td class="col-75">{{identityVerify.sendApplicationTime | timeString('YYYY-MM-DD HH:mm')}}</td>
              <td class="col-50"><i class="icon-monitor pointIcon" @click="toggleDetail(`collapse-${identityVerifyIndex}`)"></i></td>
            </tr>
            <tr :key="`${identityVerify.id}-2`" :ref="`collapse-${identityVerifyIndex}`" class="collapse" data-parent="#dataTable">
              <td colspan="6">
                <div class="m-1">
                  <div class="d-flex justify-content-end">
                    <a class="mr-2 text-primary pointIcon" @click="showIdentityVerifyPreviewModal(identityVerify)">
                      {{ identityVerify.status == Const().APPLICATION_STATUS_UNDER_REVIEW ? '審核' :'預覽' }}
                      <i class="icon-eye pointIcon"></i>
                    </a>
                  </div>
                  <div v-if="identityVerify.status == Const().APPLICATION_STATUS_APPROVED" class="mb-2 text-left" >
                    <div class="text-primary">通過時間</div>
                    <div>{{identityVerify.approvedTime | timeString('YYYY-MM-DD HH:mm')}}</div>
                  </div>
                  <div v-if="identityVerify.rejectLog" class="mb-2 text-left rejectLog" >
                    <div class="text-primary">拒絕紀錄</div>
                    <ul>
                      <li v-for="(reject, index) in identityVerify.rejectLog" :key="index">
                        <div class="text-secondary">拒絕時間</div>
                        <div>{{reject.rejectTime | timeString('YYYY-MM-DD HH:mm')}}</div>
                        <div class="text-secondary">審核拒絕原因</div>
                        <div>{{reject.rejectReason}}</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <div v-else id="result-hint" class="d-flex justify-content-center">
          <template v-if="isEmptyList">尚無資料</template>
          <template v-else><loading/></template>
        </div>
      </div>
      <!-- Pagination -->
      <ul v-if="currentPageDataList.length > 0" class="pagination justify-content-center">
        <li class="page-item">
          <a class="page-link" @click="currentPage != 1 ? getPreviousPage(currentPage, getIdentityVerifyList):''"><i class="fa fa-angle-left"></i></a>
        </li>
        <template v-for="page in range">
          <li class="page-item" :class="{'active': page === currentPage}" :key="page">
            <a class="page-link" @click="currentPage != page ? toTargetPage(page, getIdentityVerifyList):''" style="cursor: default;">{{page}}</a>
          </li>
        </template>
        <li class="page-item">
          <a class="page-link" @click="currentPage != totalPage ? toNextPage(currentPage, totalPage, getIdentityVerifyList):''"><i class="fa fa-angle-right"></i></a>
        </li>
      </ul>
      <!-- end: Pagination -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import Loading from "@/components/common/Loading";
import { mapGetters, mapMutations } from 'vuex'
import {utilMixin} from "@/mixins/utilMixin";
import { findIdentityVerifyList, updateApplicationReview} from "@/service/api/internal/admin/identityVerify";

export default {
  name: "AllIdentityVerifyList",
  mixins: [utilMixin],
  components: {
    Loading
  },
  data(){
    return{
      search:{
        keyword: '',
        status: '',
      }
    }
  },
  computed: {
    ...mapGetters([
      'getIdentityVerifyStatusName', 'getIdentityVerifyStatusStyle', 'getIdentityVerifyStatusIcon'
    ]),
  },
  mounted() {
    this.initSearchParam()
    this.getIdentityVerifyList(this.currentPage)
  },
  methods: {
    ...mapMutations(['showModal', 'hideModal']),
    initSearchParam(){
      let search = JSON.parse(JSON.stringify(this.search))
      this.search = this.initSearchData(search)
      if(!this.search.status){
        this.search.status= ''
      }
    },
    async getIdentityVerifyList(page){
      let currentPage = this.checkPageValid(page, this.currentPage, this.totalPage)
      this.currentPage = currentPage
      let query = this.getSearchQuery(this.currentPage, this.show, this.search)
      let response = await findIdentityVerifyList(query)
      this.currentPageDataList = response.data
      this.totalPage = response.totalPage
      this.totalData = response.totalElement
      if(this.currentPageDataList.length == 0){
        this.isEmptyList = true
      }
    },
    showIdentityVerifyPreviewModal(identityVerify){
      this.showModal({
        modalId: ModalList.IDENTITY_VERIFY_PREVIEW_MODAL,
        payload: {
          id: identityVerify.id,
          status: identityVerify.status,
          updateFunc: this.updateUserApplicationReview
        }
      })
    },
    async updateUserApplicationReview(data){
      this.showModal({
        modalId: ModalList.LOADING_MODAL,
      })

      let res = await updateApplicationReview(JSON.stringify({
        id: data.id,
        status: data.status,
        rejectReason: data.reason
      }))
      if(res.status < 299) {
        await this.getIdentityVerifyList(this.currentPage)
      }
      window.setTimeout(async () => {
        this.hideModal()
        res.status < 299 ? this.showSuccessModal({maxCountDownSec: 3, content: `已成功更新驗證狀態`}) : this.showErrorModal()
      },1000)
    },
    toggleDetail(refId){
      if(this.$refs[refId]){
        $(this.$refs[refId]).collapse('toggle')
      }
    },
    resetSearch(){
      this.search = {}
      this.search.keyword = ""
      this.search.status = ""
      if(window.location.search) {
        this.searchByQuery(this.getIdentityVerifyList, {page: 1, ...this.search})
      }
    }
  }
}
</script>

<style scoped>
.pointIcon{
  cursor: pointer;
  color: rgb(24 135 255)
}

.table-outter {
  overflow-x: scroll;
}

.col-100 {
  min-width: 100px;
}

.col-75 {
  min-width: 75px;
}

.col-50 {
  min-width: 50px;
}

.rejectLog ol,
.rejectLog ul{
  padding-inline-start: 40px;
}
</style>
