import axios from "@/plugins/axios";

// identityVerify 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/admin/identityVerify'

// find identityVerify by id
export function findIdentityVerify(id){
    return axios.get(`${baseUrl}/${id}`).then(res=>res.data)
}

// find identityVerify list
export function findIdentityVerifyList(query){
    return axios.get(`${baseUrl}/list?${query}`).then(res=>res.data)
}

// update application identityVerify review
export function updateApplicationReview(dto) {
    return axios.put(`${baseUrl}/review`, dto, {headers: {'Content-Type': 'application/json'}})
}

// update identityVerify status
export function updateStatus(dto){
    return axios.put(`${baseUrl}/status`, dto, { headers: {'Content-Type': 'application/json'} })
}
