<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bold text-center">
          {{title}}
        </div>
        <div class="modal-body row text-break">
          <i class="col-4 fas fa-exclamation fa-5x align-self-center text-center"></i>
          <div id="modalHintContent" class="col-8 align-self-center text-bold text-secondary">
            <div>{{content}}</div>
            <template v-if="data.isSkipReview">
              <div class="text-primary mt-2">使用者編號</div>
              {{data.userId}}
              <div class="text-primary mt-2">電子信箱</div>
              <div>{{data.email}}</div>
              <div class="form-group mt-2">
                <label for="note">管理員備注(必填)</label>
                <textarea class="form-control" id="note"
                          cols="50" rows="5" style="resize: none;"
                          v-model="data.note"></textarea>
              </div>
            </template>
            <template v-else>
              <div class="text-primary mt-2">發佈單位</div>
              {{data.identityVerify.publisher}}
              <div class="text-primary mt-2">聯絡人姓名</div>
              <div>{{data.identityVerify.contactName}}</div>
              <div class="text-primary mt-2">聯絡人email</div>
              <div>{{data.identityVerify.contactEmail}}</div>
              <div v-if="data.status == Const().APPLICATION_STATUS_REJECTED">
                <div class="form-group mt-2">
                  <label for="rejectReason">審核拒絕原因(必填)</label>
                  <textarea class="form-control" id="rejectReason"
                            cols="50" rows="5" style="resize: none;"
                            v-model="data.rejectReason"></textarea>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button"
                  class="btn btn-info"
                  data-dismiss="modal"
                  @click="hide">取消</button>
          <button type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  :disabled="checkInvalid()"
                  @click="confirmUpdate">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import { mapMutations } from "vuex";
import Const from "@/const";
import {utilMixin} from "@/mixins/utilMixin";

export default {
  name: "UpdateIdentityVerifyModal",
  mixins: [utilMixin],
  data(){
    return {
      modalId: ModalList.UPDATE_IDENTITY_VERIFY_MODAL,
      title: '',
      content: '',
      data: {
        identityVerify: {},
        status: '',
        rejectReason: '',
        isSkipReview: false,
        userId: '',
        email: '',
        note: '',
        updateFunc: () => {}
      }
    }
  },
  methods: {
    ...mapMutations({
      showModal: 'showModal'
    }),
    show(data){
      data = data ? data : {}
      this.data.identityVerify = data.identityVerify ? data.identityVerify : {}
      this.data.status = data.status ? data.status : ''
      this.data.rejectReason = ''
      this.data.isSkipReview = data.isSkipReview ? data.isSkipReview : false
      this.data.userId = data.userId ? data.userId : ''
      this.data.email = data.email ? data.email : ''
      this.data.note = ''
      this.data.updateFunc = data.updateFunc ? data.updateFunc : null
      if(this.data.status == Const.APPLICATION_STATUS_APPROVED){
        this.title = this.data.isSkipReview? '確認更新使用者的身份驗證狀態？' : '確認通過使用者的身份驗證？'
        this.content = "通過身份驗證後，使用者將可以使用發文功能。"
      }else if (this.data.status == Const.APPLICATION_STATUS_REJECTED) {
        this.title = this.data.isSkipReview? '確認更新使用者的身份驗證狀態？' : '確認駁回使用者的身份驗證？'
        this.content = this.data.isSkipReview? '取消身份驗證後，使用者將無法使用發文功能。': '駁回身份驗證後，使用者需重新送出驗證申請。'
      }
      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    checkInvalid(){
      if(this.data.isSkipReview){
        return this.data.note.trim().length == 0;
      }
      if(this.data.status == Const.APPLICATION_STATUS_APPROVED){
        return false
      }else {
        return this.data.rejectReason.trim().length == 0;
      }
    },
    confirmUpdate(){
      this.data.updateFunc({
        id: this.data.isSkipReview? this.data.userId : this.data.identityVerify.id,
        status: this.data.status,
        reason: this.data.isSkipReview? this.data.note : this.data.rejectReason
      })
    },
  }
}
</script>

<style scoped>

</style>
