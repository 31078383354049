<template>
  <div class="widget">
    <div class="col">
      <div class="team-member">
        <!-- 廣告版位 -->
        <div class="mb-2">
          <div :id="selectorId" :data-place="uuid"></div>
        </div>
        <!-- 廣告版位 -->
        <hr v-if="showContact == true">
        <div v-if="showContact == true" class="team-desc">
          <router-link v-if="userInfo.publisher"
              :to="'/publisher?q='+ userId + '&publisher='+ userInfo.publisher">
            <h5>發佈單位名稱：{{userInfo.publisher}}</h5>
          </router-link>
          <div class="org-info">
            <ul style="list-style-type: none">
              <li v-if="userInfo.contactName" style="padding: 5px">新聞聯絡人：{{userInfo.contactName}}</li>
              <li v-if="userInfo.contactPhone" style="padding: 5px">聯絡電話：{{userInfo.contactPhone}}</li>
              <li v-if="userInfo.contactEmail" style="padding: 5px">聯絡信箱：<a :href="`mailto:${userInfo.contactEmail}`">{{userInfo.contactEmail}}</a></li>
              <li v-if="userInfo.description" style="padding: 5px">{{userInfo.description}}</li>
            </ul>
          </div>
          <div class="align-center">
            <a v-if="userInfo.contactFacebook" target="_blank"
                class="btn btn-xs btn-slide btn-light" :href="'https://facebook.com/'+userInfo.contactFacebook">
              <i class="fab fa-facebook-f"></i>
              <span>Facebook</span>
            </a>
            <a v-if="userInfo.contactTwitter" target="_blank"
                class="btn btn-xs btn-slide btn-light" :href="'https://twitter.com/'+userInfo.contactTwitter" data-width="100">
              <i class="fab fa-twitter"></i>
              <span>Twitter</span>
            </a>
            <a v-if="userInfo.contactInstagram" target="_blank"
                class="btn btn-xs btn-slide btn-light" :href="'https://www.instagram.com/'+userInfo.contactInstagram" data-width="118">
              <i class="fab fa-instagram"></i>
              <span>Instagram</span>
            </a>
            <a v-if="userInfo.contactEmail" class="btn btn-xs btn-slide btn-light" :href="`mailto:${userInfo.contactEmail}`" data-width="80">
              <i class="icon-mail"></i>
              <span>Mail</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Const from "@/const";

export default {
  name: "PostUserInfo",
  props: ["showContact"],
  metaInfo(){
    return {
      script:this.script
    }
  },
  mounted() {
    if(Const.PROD_SERVER_URL_BASE.includes(window.location.origin)) {
      let selector = `#${this.selectorId}`;
      let invokeScript = document.createElement("script");
      invokeScript.innerHTML = `
              AotterTrek('suprAd', {
                  selector: '${selector}',
                  place: '${this.uuid}',
                  onAdLoad: () => {
                      console.log("${this.selectorId} success")
                  },
                  onAdFail: () => {
                      console.log("${this.selectorId} fail")
                  }
              })`

      document.body.appendChild(invokeScript);
    }
  },
  computed:{
    userInfo(){
      return this.$store.state.post ?  this.$store.state.post.meta : {}
    },
    userId(){
      return this.$store.state.post ?  this.$store.state.post.userId : ''
    },
    uuid(){
      if(this.$isMobile()){
        return Const.POST_SIDEBAR_MOBILE_AD_UUID
      }else {
        return Const.POST_SIDEBAR_DESKTOP_AD_UUID
      }
    },
    selectorId(){
      if(this.$isMobile()){
        return Const.POST_SIDEBAR_MOBILE_AD
      }else {
        return Const.POST_SIDEBAR_DESKTOP_AD
      }
    }
  },
}
</script>

<style scoped>

</style>
