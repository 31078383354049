<template>
  <!-- identityVerify -->
  <div>
    <div class="card">
      <div class="card-header">
        <span class="h4">企業身份驗證</span>
      </div>
      <div v-if="!isLoading" class="card-body">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <template v-if="identityVerify.status == Const().APPLICATION_STATUS_APPROVED || identityVerify.adminIdentityVerifyDirectly">
                <h5>
                  <i class="icon-alert-circle"></i>您已通過驗證
                </h5>
                <template v-if="identityVerify.adminIdentityVerifyDirectly">
                  <span class="h6">您已可發佈新聞稿。若有資料更換需求請依照下列步驟送出審核申請</span>
                </template>
                <template v-else>
                  <span class="h6">您已可發佈新聞稿。若有資料更換需求請來信 service@aotter.net</span>
                </template>
              </template>
              <template v-else>
                <h5>
                  <i class="icon-alert-circle"></i>您尚未通過驗證
                </h5>
                <span class="h6">您需要驗證帳號的企業身份才可開通發佈新聞稿功能</span>
              </template>
            </div>
            <div>
              <div class="d-flex mb-2">
                <div>第一步、聯絡信箱驗證：</div>
                <div v-if="identityVerify.contactEmailIdentityVerify" class="text-success d-flex align-items-center">
                  <i class="icon-check-circle ml-1 mr-1" style="font-size:1.25rem"></i>通過驗證
                </div>
                <div v-else class="text-danger d-flex align-items-center">
                  <i class="icon-x-circle ml-1 mr-1" style="font-size:1.25rem"></i>未驗證
                </div>
              </div>
              <div class="d-flex mb-2">
                <div>第二步、身份驗證：</div>
                <div class="d-flex align-items-center" :class="getIdentityVerifyStatusStyle(identityVerify.status)">
                  <i class="ml-1 mr-1" :class="getIdentityVerifyStatusIcon(identityVerify.status)" style="font-size:1.25rem"></i>{{getIdentityVerifyStatusName(identityVerify.status)}}
                </div>
              </div>
              <div v-if="identityVerify.status == Const().APPLICATION_STATUS_REJECTED" class="mb-2">
                <div class="mb-2">審核訊息：</div>
                <textarea :value="getLatestRejectReason()"
                          class="w-100" cols="50" rows="5" style="resize: none;" disabled />
              </div>
            </div>
          </div>
        </div>
          <div v-if="!identityVerify.contactEmailIdentityVerify">
            <h3>第一步、聯絡信箱驗證</h3>
            <div>僅公司企業、法人單位之電子信箱能通過身份驗證。若您的 email 為 gmail, yahoo, QQ 等公開免費信箱，請重新註冊帳號後進行身份驗證</div>
            <label for="contactEmail" class="form-label">*聯絡信箱</label>
            <input type="text" id="contactEmail" class="form-control" :value="identityVerify.contactEmail" readonly>
            <div class="w-100 mt-4 mb-4">
              <div class="d-flex justify-content-center">
                <button class="btn btn-lg m-t-30 mt-3"
                        :style="isFreeEmail||isSendContactEmailCheck?'cursor: not-allowed':''"
                        @click="sendContactEmailIdentityVerify"
                        :disabled="isFreeEmail||isSendContactEmailCheck">{{isSendContactEmailCheck? '驗證信件已送出' : '寄送驗證信件'}}</button>
              </div>
          </div>
        </div>
        <div v-else>
          <h3>第二步、身份驗證</h3>
          <validation-observer v-slot="{invalid}">
            <div class="form-row">
              <!-- 發佈單位名稱 -->
              <div class="form-group col-md-12">
                <label for="publisher"><small class="m-1 text-red">*</small> 發佈單位名稱</label>
                <validation-provider name="publisher" rules="publisher|required" v-slot="{ errors }">
                  <input id="publisher"
                         v-model="identityVerify.publisher"
                         :disabled="isPreview"
                         class="form-control"
                         type="text" placeholder="XX股份有限公司">
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <!-- 發佈單位統一編號 -->
              <div class="form-group col-md-12">
                <label for="businessIdNumber"><small class="m-1 text-red">*</small> 發佈單位統一編號</label>
                <validation-provider name="businessIdNumber" rules="businessIdNumber|required" v-slot="{ errors }">
                  <input id="businessIdNumber"
                         v-model="identityVerify.businessIdNumber"
                         :disabled="isPreview"
                         class="form-control"
                         inputmode="numeric" type="text" size="8" placeholder="12344321">
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <!-- 發佈單位成立登記證明 -->
              <div class="form-group col-md-12">
                <label><small class="m-1 text-red">*</small> 發佈單位成立登記證明</label>
                <label>公司法人請上傳<a href='https://findbiz.nat.gov.tw/fts/query/QueryBar/queryInit.do' target='_blank'>經濟部商工登記公式資料</a>截圖</label>
                <label>其他單位請上傳其他登記證明圖片</label>
                <validation-provider name="registrationCertificate" rules="required" v-slot="{ errors }">
                  <file-uploader
                      id="registrationCertificate"
                      v-model="identityVerify.registrationCertificate"
                      :is-preview = "isPreview"
                      :spec="{
                        sizeLimit: 5000,
                        title: '登記證明'
                      }"/>
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <!-- 新聞聯絡人 中文姓名 -->
              <div class="form-group col-md-12">
                <label for="contactName"><small class="m-1 text-red">*</small> 新聞聯絡人 中文姓名</label>
                <label>請填寫真實中文姓名</label>
                <validation-provider name="contactName" rules="contactName|required" v-slot="{ errors }">
                  <input id="contactName"
                         v-model="identityVerify.contactName"
                         :disabled="isPreview"
                         class="form-control"
                         type="text" placeholder="王小明">
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <!-- 聯絡電話 -->
              <div class="form-group col-md-12">
                <label for="contactPhone"><small class="m-1 text-red">*</small> 聯絡電話</label>
                <label>臺灣市話格式：02-12345678</label>
                <label>臺灣手機格式：0912-345678</label>
                <label>國際電話格式：+44 1234567（ + 號開頭，國際冠碼與電話號碼中間有空格）</label>
                <label>以上數字及符號皆為半形。</label>
                <validation-provider name="contactPhone" rules="contactPhone|required" v-slot="{ errors }">
                  <input id="contactPhone"
                         v-model="identityVerify.contactPhone"
                         :disabled="isPreview"
                         class="form-control"
                         type="tel" placeholder="0912-345678">
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <!-- 聯絡信箱 -->
              <div class="form-group col-md-12">
                <label><small class="m-1 text-red">*</small> 聯絡信箱</label>
                <input :value="identityVerify.contactEmail" class="form-control" readonly>
              </div>
              <!-- 聯絡人名片 -->
              <div class="form-group col-md-12">
                <label><small class="m-1 text-red">*</small> 聯絡人名片</label>
                <label>請上傳您在貴單位的名片，或是足以作為工作證明之文件截圖</label>
                <template v-for="(businessCard, index) in identityVerify.businessCard">
                  <div :key="index">
                    <file-uploader
                        :id="'businessCard-'+index"
                        v-model="identityVerify.businessCard[index]"
                        @delete-image="deleteBusinessCardFileUploader(index)"
                        :is-preview = "isPreview"
                        :spec="{
                          sizeLimit: 5000,
                          title: '登記證明'
                          }"
                        class="mt-1"
                    />
                  </div>
                </template>
                <span v-if="businessCardInvalid" class="text-red text-bold m-2">不可空白</span>
                <button v-if="!isPreview" @click="addBusinessCardFileUploader()"
                        class="btn m-t-30 mt-3 d-block" :class="{'d-none' : isPreview }">+新增</button>
              </div>
              <!-- 同意政策 -->
              <div class="form-group">
                <validation-provider ref="conditionValidator" name="policyCheck" rules="policyCheck" v-slot="{ errors }">
                  <div class="custom-control custom-checkbox">
                    <input id="terms_conditions"
                           v-model="condition"
                           :disabled="isPreview"
                           type="checkbox"  class="custom-control-input">
                    <label class="custom-control-label" for="terms_conditions">我已閱讀並同意
                      <router-link to="/policy/publish" target="_blank">刊登政策</router-link>、
                      <router-link to="/policy/tou" target="_blank">服務條款</router-link>以及
                      <router-link to="/policy/privacy" target="_blank">隱私權政策</router-link>
                      。</label>
                  </div>
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
            <button @click="sendApplication(invalid)"
                    v-if="identityVerify.status != Const().APPLICATION_STATUS_APPROVED"
                    :disabled="invalid || businessCardInvalid || isSendingApplication || isPreview"
                    class="btn btn-lg btn-block m-t-30 mt-3">{{isPreview? '審核中': '送出審核'}}</button>
          </validation-observer>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center m-50" >
        <loading/>
      </div>
    </div>
  </div>
</template>

<script>
import { utilMixin } from "@/mixins/utilMixin";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import RuleGroup from "@/rules/ruleGroup";
import Const from "@/const/index";
import FileUploader from "@/components/common/FileUploader";
import {mapGetters, mapMutations} from "vuex";
import {
  getUserIdentityVerify,
  sendContactEmailCheck,
  updateIdentityVerify
} from "@/service/api/internal/me/identityVerify";
import ModalList from "@/const/modal";
import { uploadUserRelativeImage } from "@/service/api/internal/me/media";
import Loading from "@/components/common/Loading";

Object.keys(RuleGroup).forEach((rule) => {
  extend(rule, RuleGroup[rule]);
});

export default {
  name: "IdentityVerify",
  mixins: [utilMixin],
  components:{
    ValidationObserver,
    ValidationProvider,
    FileUploader,
    Loading
  },
  data(){
    return{
      isLoading: true,
      identityVerify:{
        publisher:null,
        businessIdNumber:null,
        registrationCertificate:null,
        contactName:null,
        contactPhone:null,
        businessCard:[]
      },
      isSendContactEmailCheck: false,
      isSendingApplication: false,
      condition: false
    }
  },
  created() {
    this.getUserIdentityVerifyInfo()
  },
  computed: {
    ...mapGetters([
      'getIdentityVerifyStatusName', 'getIdentityVerifyStatusStyle', 'getIdentityVerifyStatusIcon'
    ]),
    isFreeEmail(){
      let freeEmailList = ["@gmail.com", "@yahoo.com", "@yahoo.com.tw", "@outlook.com", "@hotmail.com",
        "@live.com", "@qq.com", "@163.com", "@icloud.com", "@zoho.com", "@aol.com", "@mail.com", "@email.com",
        "@proton.com", "@protonmail.com", "@yandex.com"]
      let result = false
      let contactEmail = this.identityVerify.contactEmail
      if(contactEmail){
        for(let i=0; i < freeEmailList.length; i++){
          let ends = freeEmailList[i]
          if (contactEmail.endsWith(ends)){
            result = true
            break;
          }
        }
      }
      return result
    },
    isPreview() {
      return this.identityVerify.status == Const.APPLICATION_STATUS_APPROVED || this.identityVerify.status == Const.APPLICATION_STATUS_UNDER_REVIEW
    },
    businessCardInvalid(){
      let invalid = false
      invalid = this.identityVerify.businessCard
          .filter((item) => item !== null && item != undefined).length === 0
      return invalid
    }
  },
  watch:{
    condition(){
      if(!this.isPreview){
        this.$nextTick(this.$refs.conditionValidator.validate)
      }
    },
  },
  methods:{
    ...mapMutations(['showModal', 'hideModal']),
    async getUserIdentityVerifyInfo(){
      let res = await getUserIdentityVerify()
      this.identityVerify = res
      if(this.identityVerify.businessCard == undefined || this.identityVerify.businessCard.length == 0){
        this.identityVerify.businessCard = [null]
      }
      this.condition = this.isPreview
      this.isLoading = false
    },
    getLatestRejectReason(){
      let reason = ''
      let length = this.identityVerify.rejectLog.length
      if(this.identityVerify.rejectLog && length > 0){
        let log = this.identityVerify.rejectLog[length - 1];
        if(log.rejectReason){
          reason = log.rejectReason
        }
      }
      return reason
    },
    async sendContactEmailIdentityVerify(){
      let res = await sendContactEmailCheck()
      if(res.status == 200){
        this.isSendContactEmailCheck = true
        this.showSuccessModal({ maxCountDownSec: 3, content: '系統驗證信件已發送，請查收您的 email 信箱' })
      }else {
        this.showErrorModal()
      }
    },
    addBusinessCardFileUploader(){
      this.identityVerify.businessCard.push(null)
    },
    deleteBusinessCardFileUploader(index) {
      if(this.identityVerify.businessCard.length > 1){
        this.identityVerify.businessCard.splice(index, 1)
      }
    },
    async sendApplication(invalid){
      if(!invalid && !this.isPreview){
        if(!this.isSendingApplication)
          this.showModal({
            modalId: ModalList.LOADING_MODAL,
          })
        this.isSendingApplication = true

        if(this.identityVerify.registrationCertificate instanceof File) {
          let registrationCertificateFormData = new FormData()
          registrationCertificateFormData.append('file', this.identityVerify.registrationCertificate)
          let res = await uploadUserRelativeImage('registrationCertificate', registrationCertificateFormData)
          if(res.status < 299){
            this.identityVerify.registrationCertificate = res.data.url
          }else {
            window.setTimeout(()=>{
              this.isSendingApplication = false
              this.hideModal()
              this.showErrorModal()
              return
            },1000)
          }
        }
        if (Array.isArray(this.identityVerify.businessCard)) {
          const uploadPromises = this.identityVerify.businessCard.map(async (item, index) => {
            if (item instanceof File) {
              let businessCardFormData = new FormData()
              businessCardFormData.append('file', this.identityVerify.businessCard[index])
              let res = await uploadUserRelativeImage('businessCard', businessCardFormData)
              if(res.status < 299){
                this.identityVerify.businessCard[index] = res.data.url
              }else {
                window.setTimeout(()=>{
                  this.isSendingApplication = false
                  this.hideModal()
                  this.showErrorModal()
                  return
                },1000)
              }
            }
          });
          await Promise.all(uploadPromises);
        }

        let dto = {
          userId: this.identityVerify.userId,
          publisher: this.identityVerify.publisher.trim(),
          businessIdNumber: this.identityVerify.businessIdNumber.trim(),
          registrationCertificate: this.identityVerify.registrationCertificate,
          contactName: this.identityVerify.contactName.trim(),
          contactPhone: this.identityVerify.contactPhone.trim(),
          businessCard: this.identityVerify.businessCard.filter((item) => item !== null && item != undefined)
        }
        let res = await updateIdentityVerify(dto)
        if(res.status < 299){
          this.identityVerify = res.data
        }else {
          this.isSendingApplication = false
        }
        window.setTimeout(()=>{
          this.hideModal()
          res.status < 299 ? this.showSuccessModal({ maxCountDownSec: 3, content: `已成功送出申請` }) : this.showErrorModal()
        },1000)
      }
    },
  }
}
</script>

<style scoped>

</style>
