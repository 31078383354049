<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-9">
          <span class="h4">我的訂單管理</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="form-inline my-2 my-lg-0" style="justify-content: center">
        <input class="form-control mr-sm-2 w-size" placeholder="輸入標題關鍵字" v-model="search.title">
        <button class="btn btn-outline-success m-position" @click="searchByQuery(getUserTransactionList, {page: 1, ...search})">搜尋訂單</button>
        <button class="btn btn-danger m-position" @click="resetSearch">重設搜尋</button>
      </div>
      <div class="mt-2 d-flex flex-wrap flex-md-nowrap justify-content-end">
        <div class="d-flex">
          <div class="text-secondary align-self-center" id="resultCount">
            共<span class="mr-1 ml-1">{{totalData}}</span>筆
          </div>

          <div class="ml-3">
            <select name="state" class="pr-4 pl-2 border-0 font-weight-bold text-primary mr-2"
                    v-model="search.state" @change="searchByQuery(getUserTransactionList, {page: 1, ...search})">
              <option value="">所有訂單</option>
              <option value="PENDING">等待付款</option>
              <option value="COMPLETED">付款成功</option>
              <option value="FAILED">付款失敗</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-outter wrapper">
        <table v-if="currentPageDataList.length > 0" id="dataTable" class="table table-hover table-responsive vertical-middle text-center mt-4">
          <thead>
          <tr>
            <th scope="col" class="col-100" width="25%">訂單編號</th>
            <th scope="col" class="col-200" width="25%">標題</th>
            <th scope="col" class="col-75" width="15%">狀態</th>
            <th scope="col" class="col-75" width="15%">付費費用</th>
            <th scope="col" class="col-75" width="15%">截止時間</th>
            <th scope="col" class="col-50" width="10%">詳情</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(transaction, transactionIndex) in currentPageDataList">
            <tr :key="`${transaction.id}-1`">
              <!-- 訂單編號 -->
              <td class="col-100">{{transaction.orderId}}</td>
              <!-- 標題 -->
              <td class="col-200">{{transaction.promotion.name}}</td>
              <!-- 狀態 -->
              <td class="col-75" :class="getStateStyle(transaction.state)"><small>{{getStateName(transaction.state)}}
                <i v-if="transaction.state == 'PENDING'" class="icon-external-link ml-2" style="cursor:pointer;"
                   @click="showTransactionPendingModal(transaction.orderId)"></i></small></td>
              <!-- 付費費用 -->
              <td class="col-75"><small>$ {{transaction.amount}} NTD</small></td>
              <!-- 截止時間 -->
              <td class="col-75">
                <small>{{ transaction.expireTime | timeString('YYYY-MM-DD') }}
                  <br>{{ transaction.expireTime | timeString('HH:mm:ss') }}
                </small>
              </td>
              <!-- 詳情 -->
              <td class="col-50"><i class="icon-monitor pointIcon" @click="toggleDetail(`collapse-${transactionIndex}`)"></i></td>
            </tr>
            <tr :key="`${transaction.id}-2`" :ref="`collapse-${transactionIndex}`" class="collapse" data-parent="#dataTable">
              <td colspan="6">
                <div class="m-1">
                  <!-- 新聞稿編號 & 前往新聞稿 & 檢視 -->
                  <div class="mb-2 text-left">
                    <div class="text-primary">
                      <span class="mr-1">新聞稿編號</span>
                      <router-link :to="'/post/'+ transaction.postId" target="_blank">前往<i class="ml-1 icon-chevrons-right"></i></router-link>
                      <a class="ml-1 text-primary" style="cursor: pointer;"
                         @click="showModal({modalId: ModalList().PREVIEW_MODAL, payload: { type: 'userCurrentPost', postId: transaction.postId }})">檢視<i class="ml-1 icon-eye"></i></a>
                    </div>
                    <div>{{transaction.postId}}</div>
                  </div>
                  <!-- 推廣編號 -->
                  <div class="mb-2 text-left">
                    <div class="text-primary">
                      <span class="mr-1">推廣編號</span>
                      <a class="ml-1 text-primary" style="cursor: pointer;"
                         @click="showModal({modalId: ModalList().PROMOTION_MODAL, payload: { step: 1, mode: 'display',promotion: transaction.promotion}})">檢視<i class="ml-1 icon-eye"></i></a>
                    </div>
                    <div>{{transaction.promotion.id}}</div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <div v-else id="result-hint" class="d-flex justify-content-center">
          <template v-if="isEmptyList">尚無資料</template>
          <template v-else><loading/></template>
        </div>
      </div>
      <!-- Pagination -->
      <ul v-if="currentPageDataList.length > 0" class="pagination justify-content-center">
        <li class="page-item">
          <a class="page-link" @click="currentPage != 1 ? getPreviousPage(currentPage, getUserTransactionList):''"><i class="fa fa-angle-left"></i></a>
        </li>
        <template v-for="page in range">
          <li class="page-item" :class="{'active': page === currentPage}" :key="page">
            <a class="page-link" @click="currentPage != page ? toTargetPage(page, getUserTransactionList):''" style="cursor: default;">{{page}}</a>
          </li>
        </template>
        <li class="page-item">
          <a class="page-link" @click="currentPage != totalPage ? toNextPage(currentPage, totalPage, getUserTransactionList):''"><i class="fa fa-angle-right"></i></a>
        </li>
      </ul>
      <!-- end: Pagination -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import Loading from "@/components/common/Loading";
import { mapMutations } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { findTransactionList } from '@/service/api/internal/me/transaction';

export default {
  name: "TransactionList",
  mixins: [utilMixin],
  components: {
    Loading
  },
  data(){
    return{ search:{
        title: '',
        state: ''
      }
    }
  },
  mounted() {
    this.initSearchParam()
    this.getUserTransactionList(this.currentPage)
    if(this.$route.query.token){
      this.showModal(
          {modalId: ModalList.TRANSACTION_RESULT_MODAL,
            payload: {token: this.$route.query.token, updateTransactionList: this.getUserTransactionList}})
    }
  },
  methods:{
    ...mapMutations(['showModal']),
    initSearchParam(){
      let search = JSON.parse(JSON.stringify(this.search))
      this.search = this.initSearchData(search)
      if(!this.search.state){
        this.search.state= ''
      }
    },
    async getUserTransactionList(page){
      let currentPage = this.checkPageValid(page, this.currentPage, this.totalPage)
      this.currentPage = currentPage
      let query = this.getSearchQuery(this.currentPage, this.show, this.search)
      let response = await findTransactionList(query)
      this.currentPageDataList = response.data
      this.totalPage = response.totalPage
      this.totalData = response.totalElement
      if(this.currentPageDataList.length == 0){
        this.isEmptyList = true
      }
    },
    resetSearch(){
      let state = this.search.state
      this.search = {}
      this.search.state = state
      this.search.title = ""
      if(window.location.search) {
        this.searchByQuery(this.getUserTransactionList, {page: 1, ...this.search})
      }
    },
    toggleDetail(refId){
      if(this.$refs[refId]){
        $(this.$refs[refId]).collapse('toggle')
      }
    },
    showTransactionPendingModal(orderId){
      this.showModal({modalId: ModalList.TRANSACTION_PENDING_MODAL,
        payload: {
          title: '訂單編號：',
          orderId: orderId,
          description: '是否要繼續完成付款？'
        }})
    },
    getStateName(state){
      let status = ''
      switch (state) {
        case 'PENDING':
          status = '等待付款'
          break;
        case 'COMPLETED':
          status = '付款成功'
          break;
        case 'CANCELED':
          status = '訂單取消'
          break;
        case 'FAILED':
          status = '付款失敗'
          break;
      }
      return status
    },
    getStateStyle(state){
      let style = ''
      switch (state) {
        case 'PENDING':
          style = 'text-warning'
          break;
        case 'COMPLETED':
          style = 'text-success'
          break;
        case 'CANCELED':
          style = 'text-secondary'
          break;
        case 'FAILED':
          style = 'text-danger'
          break;
      }
      return style
    }
  }
}
</script>

<style scoped>
.pointIcon{
  cursor: pointer;
  color: rgb(24 135 255)
}

.table-outter {
  overflow-x: scroll;
}

.col-200 {
  min-width: 200px;
}

.col-100 {
  min-width: 100px;
}

.col-75 {
  min-width: 75px;
}

.col-50 {
  min-width: 50px;
}

@media only screen and (max-width: 576px) {
  .m-position{
    margin-top: 0.5rem!important;
    margin-bottom: 0.5rem!important;
  }
}

@media only screen and (min-width: 576px) {
  .w-size {
    width: 50% !important;
  }

  .m-position{
    margin-bottom: 0 !important;
  }
}
</style>
