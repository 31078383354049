<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-confirm">
      <div class="modal-content">

        <div class="modal-header pb-0 text-bold">
          <div style="font-size: 1.3rem;" id="myModalLabel" class="mb-3">設定預設封面</div>
        </div>

        <div class="modal-body">
          <form class="form-validate">
            <div class="form-group col-md-12">
              <cropper-file-uploader
                v-model="croppedCover"
                :spec="{
                  sizeLimit: 5000,
                  title: '預設封面',
                  isCrop: true,
                  autoCropWidth: 1200,
                  autoCropHeight: 628,
                  fixedNumber: [1200, 628],
                  frameWidth: 100
                }"
                :prev-modal-id="modalId"
                @hide-prev-modal="hide"
              />
            </div>
            <div class="modal-footer border-0">
              <div class="text-danger">建議大小 1200 * 628 (5 MB 內)</div>
              <button type="button" class="btn btn-info" @click="hide">取消</button>
              <button type="button" class="btn" @click="save">確定</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import CropperFileUploader from "@/components/common/CropperFileUploader";
import ModalList from "@/const/modal";
import { utilMixin } from "@/mixins/utilMixin";
import { mapMutations } from "vuex";
import { getDefaultCover, createDefaultCover } from '@/service/api/internal/me/post';

export default {
  name: "DefaultCoverModal",
  mixins: [utilMixin],
  components: {
    CropperFileUploader
  },
  data(){
    return {
      modalId: ModalList.DEFAULT_COVER_MODAL,
      isUpdateError: false,
      croppedCover: null,
    }
  },
  methods: {
    ...mapMutations(['showModal', 'hideModal']),
    async init(){
      this.croppedCover = null
      let res = await getDefaultCover()
      if(res.status == 200){
        if(res.data){
          this.croppedCover = res.data.cover
        }
      }
    },
    show(data){
      if(data && !data.showPrevData){
        this.init()
      }
      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    async save(){
      if(this.croppedCover instanceof File){
        const formData = new FormData()
        formData.append('file', this.croppedCover)
        this.hide()
        this.showModal({
          modalId: ModalList.LOADING_MODAL,
        })
        await createDefaultCover(formData)
            .then(res => {
              window.setTimeout(()=>{
                this.hideModal()
                res.status == 200 ? this.showSuccessModal() : this.showErrorModal()
              },1000)
            })
      }
    }
  }
}
</script>

<style scoped>

.image-upload {
  border: 1px dotted #dee2e6;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload:hover {
  cursor: pointer;
  border: 2px solid #4fc3e3;
}

.img-thumbnail {
  border: 1px solid #eee;
  padding: 1px;
}

</style>
