<template>
  <div style="text-align:left">
    <!-- Page title -->
    <div class="page-title mb-4">
      <h4>{{title}}</h4>
      <p v-if="type != 'latest'"
          id="pageResult" class="text-right">
        {{currentPageDataList.length >0 ? '第 '+pageStartPostNum +' - '+ pageEndPostNum+'筆，':'' }}
        共 {{totalData}} 筆，每頁 10 筆
        </p><br>
    </div>
    <!-- end: Page title -->
    <!-- Blog -->
    <div v-if="currentPageDataList.length > 0" id="blog" class="post-thumbnails">
      <template v-for="(post, index) in currentPageDataList">
        <!-- Post item-->
        <div class="post-item" :key="post.id">
          <div class="post-item-wrap">
            <div class="index post-image w-100-sm">
              <router-link :to="`/post/${post.id}`"
                           @click.native="scrollToTop">
                <img alt="" :src="post.cover">
              </router-link>
              <span class="post-meta-category category-show">
                <router-link :to="post.category ? `/category/${post.category[0].category}` : '/category/News'"
                             @click.native="scrollToTop">
                  {{ post.category ? post.category[0].categoryZh : '新聞'}}
                </router-link>
              </span>
            </div>
            <div class="index post-item-description">
                <span class="post-meta-date">
                  <i class="fa fa-calendar-o"></i>{{ post.publishedTime | timeString('MMM DD,YYYY')}}
                </span>
              <template v-if="post.category">
                <template v-for="category in post.category">
                    <span class="post-meta-category" :key="category.id">
                      <span class="text-bold mr-1" style="font-size: small">
                        <router-link :to="`/category/${category.category}`"
                                     @click.native="scrollToTop">
                          <i class="fa fa-tag"></i>{{category.categoryZh}}
                        </router-link>
                      </span>
                      <template v-if="category.subCategory">
                         <template v-for="subCategory in category.subCategory">
                           <router-link :to="`/category/${category.category}/sub/${subCategory.industry}`" :key="subCategory.id"
                                        @click.native="scrollToTop">
                             <i class="fa fa-tag"></i>
                             {{subCategory.industryZh}}
                           </router-link>
                         </template>
                      </template>
                    </span>
                </template>
              </template>
              <template v-else>
                  <span class="post-meta-category text-bold">
                    <span class="text-bold mr-1" style="font-size: small">
                      <router-link to="/category/News"
                                   @click.native="scrollToTop">
                        <i class="fa fa-tag"></i>新聞
                      </router-link>
                    </span>
                    <router-link to="/category/News/sub/News"
                                 @click.native="scrollToTop">
                      <i class="fa fa-tag"></i>新聞時事
                    </router-link>
                  </span>
              </template>
              <span v-if="post.promoting == true" class="post-meta-status text-primary">
                  <i class="fa fa-star"></i>贊助
                </span>
              <h2>
                <router-link :to="`/post/${post.id}`"
                             @click.native="scrollToTop">
                  {{post.title}}
                </router-link>
              </h2>
              <p class="force-line-break" v-html="post.summary"></p>
              <router-link :to="`/post/${post.id}`"
                           @click.native="scrollToTop"
                           class="item-link btn btn-sm">
                看全文 <i class="icon-chevron-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <!-- end: Post item-->
        <!-- Post item quote-->
        <template v-if="type == 'latest'">
          <div v-if="index === 5 && specialSelectedPost != null" :key="'quote-'+index" class="post-item quote">
          <div class="post-item-wrap">
            <div class="post-quote-img pt-3">
              <a href="#">
                <img src="/assets/images/quote.png">
              </a>
              <span class="post-meta-category">贊助</span>
            </div>
            <div class="post-item-description">
              <span class="post-meta-date"><i class="fa fa-calendar-o"></i>{{ specialSelectedPost.post.publishedTime | timeString('MMM DD,YYYY')}}</span>
              <template v-if="specialSelectedPost.post.category">
                <template v-for="category in specialSelectedPost.post.category">
                    <span class="post-meta-category" :key="category.id">
                      <span class="text-bold mr-1" style="font-size: small">
                        <router-link :to="`/category/${category.category}`"
                                     @click.native="scrollToTop">
                          <i class="fa fa-tag"></i>{{category.categoryZh}}
                        </router-link>
                      </span>
                      <template v-if="category.subCategory">
                         <template v-for="subCategory in category.subCategory">
                           <router-link :to="`/category/${category.category}/sub/${subCategory.industry}`"
                                        :key="subCategory.id"
                                        @click.native="scrollToTop">
                             <i class="fa fa-tag"></i>{{subCategory.industryZh}}
                           </router-link>
                         </template>
                      </template>
                    </span>
                </template>
              </template>
              <h2>
                <router-link :to="`/post/${specialSelectedPost.post.id}`"
                               @click.native="scrollToTop">
                  {{ specialSelectedPost.post.title }}
                </router-link>
              </h2>
              <p class="force-line-break" v-html="specialSelectedPost.post.summary"></p>
              <router-link :to="`/post/${specialSelectedPost.post.id}`"
                           @click.native="scrollToTop"
                           class="item-link btn btn-sm btn-light">
                了解詳情 <i class="icon-chevron-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        </template>
        <!-- end: Post item-->
      </template>
    </div>
    <div v-else id="result-hint" class="d-flex justify-content-center">
      <template v-if="isEmptyList">尚無資料</template>
      <template v-else><loading/></template>
    </div>
    <!-- end: Blog -->
    <!-- Pagination -->
    <ul v-if="currentPageDataList.length>0" class="pagination justify-content-center">
      <li class="page-item">
        <a class="page-link" @click="currentPage != 1 ? getPreviousPage(currentPage, findCurrentPagePostList):''"><i class="fa fa-angle-left"></i></a>
      </li>
      <template v-for="page in range">
        <li class="page-item" :class="{'active': page === currentPage}" :key="page">
          <a class="page-link" @click="currentPage != page ? toTargetPage(page, findCurrentPagePostList):''" style="cursor: default;">{{page}}</a>
        </li>
      </template>
      <li class="page-item">
        <a class="page-link" @click="currentPage != totalPage ? toNextPage(currentPage, totalPage, findCurrentPagePostList):''"><i class="fa fa-angle-right"></i></a>
      </li>
    </ul>
    <!-- end: Pagination -->
  </div>
</template>

<script>
import Const from '../../const'
import Loading from "@/components/common/Loading";
import { mapMutations } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { findAllPublishedByUrl, findSpecialSelectedPost } from '@/service/api/public';

export default {
  name: "PostList",
  mixins: [utilMixin],
  props:[
      'type'
  ],
  components: {
    Loading
  },
  data(){
    return{
      search:{
        q:'',
        publisher:''
      },
    }
  },
  mounted() {
    this.initSearchParam()
    this.findCurrentPagePostList(this.currentPage)
    this.getSpecialSelectedPost(this.currentPage)
  },
  computed:{
    title() {
      let title = ''
      let categoryMap = {}
      let result
      switch (this.type) {
        case Const.TYPE_LATEST:
          title = '最新新聞稿'
          break;
        case Const.TYPE_TAG:
          title = '標籤「' + this.$route.query.q + '」'
          break;
        case Const.TYPE_SEARCH:
          title = '搜尋「' + this.$route.query.q + '」'
          break;
        case Const.TYPE_CATEGORY:
          categoryMap.category = this.$route.params.main
          result = this.getCategoryZhMap(categoryMap)
          title = result.categoryZh? `分類：${result.categoryZh}`:'';
          break;
        case Const.TYPE_SUBCATEGORY:
          categoryMap.category = this.$route.params.main
          categoryMap.subCategory = this.$route.params.sub
          result = this.getCategoryZhMap(categoryMap)
          title = result.categoryZh&&result.subCategoryZh ? `分類：${result.categoryZh}、子分類：${result.subCategoryZh}`:''
          break;
        case Const.TYPE_PUBLISHER:
          title = '發佈單位「' + this.$route.query.publisher + '」'
          break;
      }
      return title
    },
    pageStartPostNum(){
      return (10 * this.currentPage) - 9
    },
    pageEndPostNum(){
      let pageEndPostNum = this.totalData
      if(this.totalData > 10 && this.totalPage != this.currentPage){
          pageEndPostNum = 10 * this.currentPage
      }
      return pageEndPostNum
    },
    specialSelectedPost(){
      return this.$store.state.specialSelectedPost
    },
  },
  methods:{
    ...mapMutations(['updateSpecialSelectedPost']),
    initSearchParam(){
      let search = JSON.parse(JSON.stringify(this.search))
      this.search = this.initSearchData(search)
    },
    async findCurrentPagePostList(page){
      let currentPage = this.checkPageValid(page, this.currentPage, this.totalPage)
      this.currentPage = currentPage
      let query = this.getSearchQuery(this.currentPage, this.show, this.search)
      let url
      switch (this.type){
        case Const.TYPE_LATEST:
          url = `${Const.PUBLIC_POST_PATH}?${query}`
          break;
        case Const.TYPE_SEARCH:
          url =`${Const.PUBLIC_POST_PATH}/search?${query}`
          break;
        case Const.TYPE_TAG:
          url = `${Const.PUBLIC_POST_PATH}/tag?${query}`
          break;
        case Const.TYPE_CATEGORY:
        case Const.TYPE_SUBCATEGORY:
          url = `${Const.PUBLIC_POST_PATH}/${this.getCategoryPathParams()}?${query}`
          break;
        case Const.TYPE_PUBLISHER:
          url = `${Const.PUBLIC_POST_PATH}/publisher?${query}`
      }

      let response = await findAllPublishedByUrl(url)
      this.currentPageDataList = response.data
      this.totalPage = response.totalPage
      this.totalData = response.totalElement
      if(this.currentPageDataList.length == 0){
        this.isEmptyList = true
      }
    },
    getCategoryZhMap(categoryMap){
      let map = {}
      this.$store.state.categoryList.forEach(item =>{
        if(item.category === decodeURI(categoryMap.category)){
          map.categoryZh = item.categoryZh
          item.subCategory.forEach(subItem =>{
            if(subItem.industry == decodeURI(categoryMap.subCategory)){
              map.subCategoryZh = subItem.industryZh
            }
          })
        }
      })
      return map
    },
    getCategoryPathParams(){
      if(this.type == Const.TYPE_CATEGORY){
        return `category/${this.$route.params.main}`
      }else {
        return `category/${this.$route.params.main}/sub/${this.$route.params.sub}`
      }
    },
    async getSpecialSelectedPost(page){
      await findSpecialSelectedPost(page).then(result =>{
        if(result){
          this.updateSpecialSelectedPost(result)
        }
      })
    }
  }
}
</script>

<style scoped>
.force-line-break{
  width: 100%;
  height: auto;
  word-wrap:break-word;
  word-break:break-all;
  overflow: hidden;
}

@media only screen and (max-width: 576px) {
  .category-show{
    display: none !important;
  }
}
</style>
