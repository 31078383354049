<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-9">
          <span class="h4">使用者管理</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="form-inline my-2 my-lg-0">
        <div class="w-100">
          <input class="form-control mr-sm-2 w-100 mb-2" placeholder="輸入信箱" v-model="search.email">
          <input class="form-control mr-sm-2 w-100 mb-2" placeholder="輸入單位" v-model="search.publisher">
          <input class="form-control mr-sm-2 w-100 mb-2" placeholder="輸入使用者編號" v-model="search.userId">
          <div class="d-flex">
            <div class="form-group w-50">
              <label class="mr-3" for="state">帳號狀態</label>
              <select id="state" class="w-100 form-control mr-2" v-model="search.state">
                <option value="">全部</option>
                <option value="ACTIVE">正常</option>
                <option value="DEACTIVATED">已停權</option>
              </select>
            </div>
            <div class="form-group w-50">
              <label class="mr-3" for="identityVerify">身份驗證</label>
              <select id="identityVerify" class="w-100 form-control" v-model="search.identityVerify">
                <option value="">全部</option>
                <option value="true">已驗證</option>
                <option value="false">未驗證</option>
              </select>
            </div>
          </div>
        </div>
        <div class="w-100 mt-3 d-flex justify-content-end">
          <button class="btn btn-outline-success my-2 my-sm-0" @click="searchByQuery(getUserList, {page: 1, ...search})">搜尋</button>
          <button class="btn btn-danger my-2 my-sm-0" @click="resetSearch">重設搜尋</button>
        </div>
      </div>
      <div class="mt-2 d-flex flex-wrap flex-md-nowrap justify-content-end">
        <div class="d-flex">
          <div class="text-secondary align-self-center" id="resultCount">
            共<span class="mr-1 ml-1">{{totalData}}</span>筆
          </div>

          <div class="ml-3">
            <select name="role" class="border-0 font-weight-bold text-primary mr-2"
                    v-model="search.role" @change="searchByQuery(getUserList, {page: 1, ...search})">
              <option value="">所有角色</option>
              <option value="NORMAL">使用者</option>
              <option value="ADMIN">管理員</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-outter wrapper">
        <table v-if="currentPageDataList.length > 0" id="dataTable" class="table table-hover table-responsive vertical-middle text-center mt-4">
          <thead>
          <tr>
            <th scope="col" class="col-100" width="30%">電子信箱</th>
            <th scope="col" class="col-75" width="30%">單位</th>
            <th scope="col" class="col-75" width="10%">狀態</th>
            <th scope="col" class="col-75" width="10%">身份驗證</th>
            <th scope="col" class="col-75" width="15%">角色</th>
            <th scope="col" class="col-50" width="10%">詳情</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(user, userIndex) in currentPageDataList">
            <tr :key="`${user.id}-1`">
              <td class="col-100">{{user.email}}</td>
              <td class="col-75">{{user.meta.publisher?user.meta.publisher:'--'}}</td>
              <td class="col-75">
                {{isUserStateActive(user.state) ? '正常' : '已停權'}}
              </td>
              <td class="col-75">
                {{user.meta.identityVerify? '已驗證':'未驗證'}}
              </td>
              <td class="col-75">
                <template v-for="(role, index) in user.role">
                  <div :key="index">{{getRoleName(role)}}</div>
                </template>
              </td>
              <td class="col-50"><i class="icon-monitor pointIcon" @click="toggleDetail(`collapse-${userIndex}`)"></i></td>
            </tr>
            <tr :key="`${user.id}-2`" :ref="`collapse-${userIndex}`" class="collapse" data-parent="#dataTable">
              <td colspan="6">
                <div class="m-1">
                  <div class="mb-3 text-left" >
                    <div class="d-flex">
                      <a class="mr-2 pointIcon" :class="isRolesContainAdmin(user.role) ? 'text-danger' : 'text-success'"
                         @click="showUpdateAdminRoleModal(user.id, user.role, user.email)">
                        管理員<i class="ml-1" :class="isRolesContainAdmin(user.role) ? 'icon-trash' : 'icon-plus-square'"></i>
                      </a>
                      <div> | </div>
                      <a class="mr-2 ml-2 pointIcon" :class="isUserStateActive(user.state) ? 'text-danger' : 'text-success'"
                         @click="showUpdateUserStateModal(user.id, user.email, user.state)">
                        {{isUserStateActive(user.state) ? '停權使用者' : '取消停權'}}
                      </a>
                      <div> | </div>
                      <a class="ml-2 pointIcon" :class="user.meta.identityVerify ? 'text-danger' : 'text-success'"
                         @click="showUpdateUserIdentityVerifyModal(user.id, user.email, !user.meta.identityVerify)">
                        {{user.meta.identityVerify ? '取消驗證' : '驗證'}}
                      </a>
                    </div>
                  </div>
                  <div class="mb-2 text-left">
                    <div class="text-primary">
                      <span class="mr-1">編號</span>
                    </div>
                    <div>{{user.id}}</div>
                  </div>
                  <div class="mb-2 text-left">
                    <div class="text-primary">
                      <span class="mr-1">公開資訊</span>
                    </div>
                    <div>{{user.meta.publisher?user.meta.publisher:'--'}}</div>
                    <div>{{user.name?user.name:'--'}}</div>
                    <div>{{user.meta.contactPhone?user.meta.contactPhone:'--'}}</div>
                    <div>{{user.meta.description?user.meta.description:'--'}}</div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <div v-else id="result-hint" class="d-flex justify-content-center">
          <template v-if="isEmptyList">尚無資料</template>
          <template v-else><loading/></template>
        </div>
      </div>
      <!-- Pagination -->
      <ul v-if="currentPageDataList.length > 0" class="pagination justify-content-center">
        <li class="page-item">
          <a class="page-link" @click="currentPage != 1 ? getPreviousPage(currentPage, getUserList):''"><i class="fa fa-angle-left"></i></a>
        </li>
        <template v-for="page in range">
          <li class="page-item" :class="{'active': page === currentPage}" :key="page">
            <a class="page-link" @click="currentPage != page ? toTargetPage(page, getUserList):''" style="cursor: default;">{{page}}</a>
          </li>
        </template>
        <li class="page-item">
          <a class="page-link" @click="currentPage != totalPage ? toNextPage(currentPage, totalPage, getUserList):''"><i class="fa fa-angle-right"></i></a>
        </li>
      </ul>
      <!-- end: Pagination -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Const from "@/const";
import ModalList from "@/const/modal";
import Loading from "@/components/common/Loading";
import { mapGetters, mapMutations } from 'vuex'
import { utilMixin } from "@/mixins/utilMixin"
import { findUserList, updateRoles, updateState } from "@/service/api/internal/admin/user";
import { updateStatus } from "@/service/api/internal/admin/identityVerify";

export default {
  name: "UserList",
  mixins: [utilMixin],
  components: {
    Loading
  },
  data(){
    return{
      search:{
        email: '',
        publisher: '',
        userId: '',
        role: '',
        state:'',
        identityVerify:'',
      }
    }
  },
  computed: {
    ...mapGetters(['getRoleName'])
  },
  mounted() {
    this.initSearchParam()
    this.getUserList(this.currentPage)
  },
  methods: {
    ...mapMutations(['showModal', 'hideModal']),
    initSearchParam(){
      let search = JSON.parse(JSON.stringify(this.search))
      this.search = this.initSearchData(search)
      if(!this.search.role){
        this.search.role= ''
      }
      if(!this.search.state){
        this.search.state= ''
      }
      if(!this.search.identityVerify){
        this.search.identityVerify= ''
      }
    },
    async getUserList(page){
      let currentPage = this.checkPageValid(page, this.currentPage, this.totalPage)
      this.currentPage = currentPage
      let query = this.getSearchQuery(this.currentPage, this.show, this.search)
      let response = await findUserList(query)
      this.currentPageDataList = response.data
      this.totalPage = response.totalPage
      this.totalData = response.totalElement
      if(this.currentPageDataList.length == 0){
        this.isEmptyList = true
      }
    },
    showUpdateAdminRoleModal(userId, roles, email){
      this.showModal({
        modalId: ModalList.UPDATE_ADMIN_ROLE_MODAL,
        payload: {
          add: !this.isRolesContainAdmin(roles),
          userId: userId,
          email: email,
          roles: roles,
          update: this.updateRole
        }})
    },
    updateRole(data){
      let role = Const.ROLE_NORMAL
      if(data.add){
        role = Const.ROLE_ADMIN
      }
      updateRoles(JSON.stringify({
        id: data.userId,
        role: role
      })).then(async res => {
        if (res.status < 299) {
          await this.getUserList(this.currentPage)
          this.showSuccessModal({maxCountDownSec: 3, content: `已成功${data.add ? '增加' : '移除'}管理員 ${data.email}`})
        } else {
          this.showErrorModal()
        }
      })
    },
    showUpdateUserStateModal(userId, email, state){
      let updateState
      switch (state) {
        case Const.USER_STATE_ACTIVE: {
          updateState = Const.USER_STATE_DEACTIVATED
          break;
        }
        case Const.USER_STATE_DEACTIVATED: {
          updateState = Const.USER_STATE_ACTIVE
          break;
        }
      }
      this.showModal({
        modalId: ModalList.UPDATE_USER_STATE_MODAL,
        payload: {
          state: updateState,
          userId: userId,
          email: email,
          update: this.updateUserState
        }})
    },
    updateUserState(data){
      updateState(JSON.stringify({
        id: data.userId,
        state: data.state,
        note: data.note
      })).then(async (res) => {
        if (res.status < 299) {
          await this.getUserList(this.currentPage)
          this.showSuccessModal({maxCountDownSec: 3, content: '已成功更新使用者帳號狀態'})
        } else {
          this.showErrorModal()
        }
      })
    },
    showUpdateUserIdentityVerifyModal(userId, email, identityVerify){
      let applicationStatus
      if(identityVerify){
        applicationStatus = Const.APPLICATION_STATUS_APPROVED
      }else {
        applicationStatus = Const.APPLICATION_STATUS_REJECTED
      }
      this.showModal({
        modalId: ModalList.UPDATE_IDENTITY_VERIFY_MODAL,
        payload: {
          isSkipReview: true,
          userId: userId,
          email: email,
          status: applicationStatus,
          updateFunc: this.updateUserIdentityVerifyStatus
        }})

    },
    async updateUserIdentityVerifyStatus(data){
      let res = await updateStatus(JSON.stringify({
        id: data.id,
        identityVerify: data.status,
        note: data.reason
      }))
      if (res.status < 299) {
        await this.getUserList(this.currentPage)
      }
      window.setTimeout(async () => {
        this.hideModal()
        res.status < 299 ? this.showSuccessModal({maxCountDownSec: 3, content: `已成功更新使用者身份驗證`}) : this.showErrorModal()
      },1000)
    },
    toggleDetail(refId){
      if(this.$refs[refId]){
        $(this.$refs[refId]).collapse('toggle')
      }
    },
    isRolesContainAdmin(roles){
      return roles.includes(Const.ROLE_ADMIN)
    },
    isUserStateActive(state){
      return state == Const.USER_STATE_ACTIVE
    },
    resetSearch(){
      let role = this.search.role
      this.search = {}
      this.search.role = role
      this.search.email = ""
      this.search.publisher = ""
      this.search.userId = ""
      this.search.state = ""
      this.search.identityVerify = ""
      if(window.location.search) {
        this.searchByQuery(this.getUserList, {page: 1, ...this.search})
      }
    }
  }
}
</script>

<style scoped>
.pointIcon{
  cursor: pointer;
  color: rgb(24 135 255)
}

.table-outter {
  overflow-x: scroll;
}

.col-100 {
  min-width: 100px;
}

.col-75 {
  min-width: 75px;
}

.col-50 {
  min-width: 50px;
}
</style>
