<template>
  <div>
    <div v-if="isEditMode && isImgExist"
         class="w-100 d-flex justify-content-end"
         style="margin-bottom: -15px">
      <i class="icon-x-circle text-danger"
         style="font-size:2rem; z-index: 10; cursor: pointer"
         @click="deleteImage"/>
    </div>
    <div class="p-0 m-0" ref="imageUpload">
      <div class="image-upload w-100"
           :class="{ 'cursor-disabled': !isEditMode }"
           @click="showUploadDialog">
        <template v-if="isImgExist" class="w-100 h-100">
          <img class="img-thumbnail h-100" :src="imageSrc"/>
        </template>
        <template v-else class="image-choose">
          <i class="icon-upload-cloud" style="font-size:5rem;"></i>
          <div class="text-secondary">選擇圖片</div>
        </template>
      </div>
      <input :ref="refId"
             :id="id"
             :key="componentKey"
             type="file"
             class="form-control d-none"
             accept="image/jpg, image/jpeg, image/png"
             @change="updateImage"
      />
      <span v-show="isUpdateError" class="font-weight-bold text-red mr-2">請上傳圖片</span>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {mapMutations} from "vuex";

export default {
  name: "FileUploader",
  props: ['prevModalId', 'spec', 'value', 'isPreview'],
  emits: ['hide-prev-modal', 'sync-image-url', 'input'],
  data(){
    return {
      id: null,
      refId: null,
      componentKey: 0,
      imageSrc: null,
      isUpdateError: false,
      file: null,
      imgData: {
        title: "上傳圖片",
        url: null,
        sizeLimit: 10000,
      }

    }
  },
  computed: {
    isImgExist(){
      let isExist = false
      if(this.imageSrc){
        isExist = true
      }
      return isExist
    },
    isEditMode(){
      let isPreview = this.isPreview != undefined ? this.isPreview : false
      return !isPreview
    },
  },
  watch: {
    value(value){
      this.syncImageSrc(value)
    }
  },
  created(){
    this.id = Math.floor(Math.random() * 1000)
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapMutations(['showModal','consumeUploaderData']),
    init(){
      let spec = this.spec ? this.spec : {}
      let id = Math.floor(Math.random() * 1000)
      this.id = spec.id? spec.id: id
      this.refId = spec.refId? spec.refId: `${spec.type}-${id}`
      this.imgData.sizeLimit = spec.sizeLimit? spec.sizeLimit : 10000

      this.syncImageSrc(this.value)
    },
    showUploadDialog(){
      if(this.isEditMode){
        $(this.$refs[this.refId]).click()
      }
    },
    updateImage(event){
      let files = event.target.files || event.dataTransfer.files
      if (!files.length){
        return
      }

      let msg = this.checkFileIsOverSize(files[0].size, this.imgData.sizeLimit)
      if(msg){
        alert(msg)
        this.resetFileInput()
        return
      }
      this.$emit('input', files[0])
    },
    resetFileInput(){
      this.$refs[this.refId].value = null
    },
    checkFileIsOverSize(fileSize, sizeLimit){
      let limit = sizeLimit? sizeLimit : this.imgData.sizeLimit
      let isOverMessage = ''
      if(fileSize > limit * 1024){
        isOverMessage = `上傳的檔案超過限制的${limit / 1000}MB`
      }
      return isOverMessage
    },
    syncImageSrc(value){
      if(value instanceof File){
        this.imageSrc = window.URL.createObjectURL(value)
        // let reader = new FileReader();
        // reader.onload = (e) => {
        //   this.imageSrc = e.target.result
        //   this.$emit('sync-image-url',this.imageSrc)
        // };
        //
        // reader.readAsDataURL(value)
      } else if(typeof value == "string"){
        this.imageSrc = value
      } else {
        this.imageSrc = null
      }
    },
    deleteImage(){
      this.$emit('input', null)
      this.$emit('delete-image')
      this.componentKey += 1;
    }
  }
}
</script>

<style scoped>

.image-upload {
  border: 1px dotted #dee2e6;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload:hover {
  cursor: pointer;
  border: 2px solid #4fc3e3;
}

.cursor-disabled:hover {
  cursor: unset;
  border: 1px dotted #dee2e6;
}
</style>
